import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { ImportFileStatus } from '../../api/partner'

interface ImportResult {
   errorMessage: string | null
   message: string
   result: {
      account: string
      id: number
      loyaltyPlanId: number
      path: string
      type: 'MEMBERS'
      status: 'PENDING' | 'COMPLETED' | 'FAILED' | 'IN_PROGRESS' // puedes ajustar si hay más estados
      processed: number | null
      notProcessed: number | null
      errors: string[] | null
      createdAt: string // puede ajustarse a Date si se procesa previamente
      updatedAt: string | null
   }
}
function useIsProcessingImport({ type }: { type: string }) {
   const [isProcessingImport, setIsProcessingImport] = useState(false)
   const [data, setData] = useState<ImportResult | null>(null)
   const [isMounted, setIsMounted] = useState(false)
   const { mutate: statusImport } = useMutation({
      mutationFn: ImportFileStatus,
   })
   useEffect(() => {
      const fetchIsProcessingRates = async () => {
         await statusImport(
            {
               type: type,
            },
            {
               onSuccess: (data) => {
                  setIsMounted(true)
                  setIsProcessingImport(true)
                  setData(data)
               },
               onError: (e: any) => {
                  console.log('error', e)
                  setIsProcessingImport(false)
               },
            },
         )
      }
      if (type) {
         fetchIsProcessingRates()
         const intervalId = setInterval(fetchIsProcessingRates, 1500)
         return () => clearInterval(intervalId)
      }
   }, [type, statusImport])

   return { isProcessingImport, data, isMounted }
}

export default useIsProcessingImport
