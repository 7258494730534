import Title from '../common/Title'
import { useIntl } from 'react-intl'
import Card from '../common/Card'
import ProgramForm from './components/ProgramForm'
import { getInitialValues } from '../../utils/programsUtils'
import { useGetProgram } from '../../hooks/useGetProgram'

function Program() {
   const intl = useIntl()
   const { data: program, isLoading, isPending } = useGetProgram()

   if (isLoading || isPending) {
      return <>'...loading'</>
   }
   return (
      <>
         <Title value={intl.formatMessage({ id: 'titles.programs' })} />
         <div className="flex flex-col gap-y-4 mx-4 ">
            <Card className="py-4 w-full lg:w-10/12">
               {program && <ProgramForm program={getInitialValues(program)} />}
            </Card>
         </div>
      </>
   )
}

export default Program
