import { useMemo, useRef } from 'react'
import qs from 'qs'
import Title from '../common/Title'
import { useIntl } from 'react-intl'
import Card from '../common/Card'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCurrentValues, partnerItemOnOperation } from '../../utils/operationUtils'
import { createDateFromISO } from 'utils/dates'
import Filters, { OperationFilterForm } from './components/Filters'
import { getOperationExportList } from '../../api/operation'
import { useMutation } from '@tanstack/react-query'
import { useAppSelector } from '../../store/hooks'
import { useGetProgramFilter } from '../../hooks/useGetProgramFilter'
import TableOperations from './components/TableOperations'
import { toast } from 'react-toastify'

export type Operation = {
   id: number
   reservationName: string
   reservationCode: string
   reservationTotal: number
   reservationDate: string
   currency: string
   points: number
   pointsAccumulated: number | null
   pointsRedeemed: number | null
   member: partnerItemOnOperation
   operationType: string
   operationStatus: string
   loyaltyPlanId: number
   levelName: string
   serviceType: string
   importId: string
   roomNights: number
   channel: string
   applicationDate: string
   createdAt: string
}

type Props = {}

export const Operations = (props: Props) => {
   const intl = useIntl()
   const { locale } = useAppSelector((state) => state.base)
   const navigate = useNavigate()
   const location = useLocation()
   const { accountCode } = useParams<{ accountCode: string }>()
   const { data: programs, isSuccess } = useGetProgramFilter()
   const divRef = useRef<HTMLDivElement>(null)
   const { mutate: exportFile } = useMutation({
      mutationFn: getOperationExportList,
   })

   const currentValues = getCurrentValues(location.search)
   const handleCopy = () => {
      if (divRef.current) {
         const textToCopy = divRef.current.textContent || ''
         navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
               toast.info('Texto copiado al portapapeles: ')
            })
            .catch((err) => {
               toast.info('Error al copiar el texto: ')
            })
      }
   }
   const columns = useMemo(
      () => [
         {
            header: 'Numero de operacion',
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <Link to={`${row.original.id}?partnerId=${row.original.member.id}`}>
                     {row.original.id ? row.original.importId : '--'}
                  </Link>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.partner' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <Link to={`/${accountCode}/partners/${row.original.member.id}`}>
                     {row.original.member.fullName}
                     <div className="text-sm text-neutral-400">{row.original.member.email}</div>
                  </Link>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.partner.id' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div ref={divRef} className="cursor-pointer" onClick={handleCopy}>
                     {row.original.member.externalId ? row.original.member.externalId : '--'}
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.generic.type' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div>{row.original.serviceType}</div>
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.type' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div>
                     {row.original.operationType
                        ? intl.formatMessage({ id: `operations.table.type.${row.original.operationType}` })
                        : '--'}
                     <div className="text-sm text-neutral-400">
                        {row.original.operationStatus
                           ? intl.formatMessage({ id: `operations.table.type.${row.original.operationStatus}` })
                           : '--'}
                     </div>
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.registrationChannel' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div>{row.original.channel}</div>
            },
            enableSorting: false,
         },
         {
            header: () => (
               <div className="text-center">{intl.formatMessage({ id: 'operations.accumulatedPoints' })}</div>
            ),
            accessorKey: 'accumulatedPoints',
            cell: ({ row }: { row: { original: Operation } }) => (
               <div className="text-center">{row.original.pointsAccumulated}</div>
            ),
            enableSorting: true,
         },
         {
            header: () => <div className="text-center">{intl.formatMessage({ id: 'operations.redeemedPoints' })}</div>,
            accessorKey: 'pointsAccumulatedRemaining',
            cell: ({ row }: { row: { original: Operation } }) => (
               <div className="text-center">{row.original.pointsRedeemed}</div>
            ),
            enableSorting: true,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.CN' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div>{row.original.roomNights}</div>
            },
            enableSorting: true,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.date.stay' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.reservationDate).setLocale(locale)
               return <div>{isoDate.toFormat(intl.formatMessage({ id: 'date.format' }))}</div>
            },
            enableSorting: true,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.date.application' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.applicationDate).setLocale(locale)
               return (
                  <div>
                     {isoDate.toFormat(intl.formatMessage({ id: 'date.format' }))}
                     <div className="text-sm text-neutral-400">{`${isoDate.toFormat('HH:mm')} hrs`}</div>
                  </div>
               )
            },
            enableSorting: true,
         },
      ],
      [intl, locale, accountCode],
   )

   const onFilter = (data: OperationFilterForm) => {
      const request = Object.assign({}, data)
      // request.from = undefined
      // request.to = undefined
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }

   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onSort = (sort: () => Array<{ id: string; desc: boolean }>) => {
      const sortField = sort()?.[0]
      if (!sortField) return
      const { sort: sortFilter, ...currentValues } = getCurrentValues(location.search)
      const sort2 = sortFilter.includes('asc')
      const request = Object.assign(
         {},
         currentValues,
         !sort2
            ? {
                 sort: `${sortField.id},${sortField.desc ? 'desc' : 'asc'}`,
              }
            : null,
      )
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   let defaultProgram = programs && programs.items ? programs.items.find((program) => program.isDefault)?.id : undefined
   const onExport = () => {
      const { loyaltyPlanIds } = currentValues
      let request = {
         account: accountCode || '',
         emailRecipients: [],
         filters: {
            ...(loyaltyPlanIds.length
               ? { loyaltyPlanIds: Number(loyaltyPlanIds) }
               : { loyaltyPlanIds: defaultProgram }),
         },
      }
      exportFile(request, {
         onSuccess: (response) => {
            let excellName = `operations.xlsx`
            const blob = new Blob([response], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
         },
      })
   }
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'titles.operations' })}
         ></Title>
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               {isSuccess && programs.items && programs.items.length > 0 && (
                  <>
                     <Filters
                        className="px-4"
                        onSubmit={onFilter}
                        defaultValues={currentValues}
                        defaultProgram={defaultProgram}
                     />

                     <TableOperations
                        currentValues={currentValues}
                        onPageChange={onPageChange}
                        onExport={onExport}
                        columns={columns}
                        defaultProgram={defaultProgram}
                        onSortChange={onSort}
                     />
                  </>
               )}
            </Card>
         </div>
      </>
   )
}
