import { HistoryItem } from '../components/partners/components/ActivityHistory'
import { apiCall } from '../utils/api'

export type GetHistoryRequest = {
   memberId: string
   page?: number
   size?: number
}

export async function getHistory(
   request: GetHistoryRequest,
): Promise<{ items: Array<HistoryItem>; totalSize: number }> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/${request.memberId}/history-points`,
      method: 'GET',
   })

   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
