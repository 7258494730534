import classNames from 'classnames'
import {
   useReactTable,
   getCoreRowModel,
   flexRender,
   getPaginationRowModel,
   getSortedRowModel,
} from '@tanstack/react-table'
import Pagination from './components/Pagination'
import { useState } from 'react'
import TableResume from './components/TableResume'
import { Button } from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons'
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'

type PropTypes = {
   data: Array<any>
   columns: Array<any>
   pagination?: {
      totalSize: number
      defaultPageSize: number
      defaultPageIndex: number
      onPageChange: (page: { page: number; size: number }) => void
   }
   showTableResume?: boolean
   fetching?: boolean
   tableContainerClassName?: string
   tableClassName?: string
   footerClassName?: string
   onExport?: () => void
   exportText?: string
   children?: any
   sorting?: {
      sorting: any
      onSortChange: (sorting: any) => void
   }
}

export const Table = ({
   data,
   columns,
   pagination,
   showTableResume,
   fetching,
   tableContainerClassName,
   footerClassName,
   tableClassName = 'border border-gray-200',
   children,
   sorting,
}: PropTypes) => {
   const [pageIndex, setPageIndex] = useState(pagination?.defaultPageIndex || 0)
   const { getHeaderGroups, getRowModel, getPageCount } = useReactTable({
      columns,
      data,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      manualPagination: true,
      defaultColumn: {
         size: 51,
         minSize: 50,
      },
      ...(pagination && {
         getPaginationRowModel: getPaginationRowModel(),
         ...(sorting && {
            manualSorting: true,
            onSortingChange: sorting.onSortChange,
         }),
         state: {
            ...(sorting && {
               sorting: sorting.sorting,
            }),
            pagination: {
               pageIndex: pageIndex,
               pageSize: pagination.defaultPageSize,
            },
         },
         pageCount: Math.ceil(pagination.totalSize / pagination.defaultPageSize),
      }),
   })

   const gotoPage = (newPage: number) => {
      setPageIndex(newPage)
      if (pagination) {
         pagination.onPageChange({
            page: newPage,
            size: pagination.defaultPageSize,
         })
      }
   }

   return (
      <div className={'flex flex-col my-6'}>
         <div
            className={classNames('relative overscroll-x-auto overflow-x-auto', tableContainerClassName, {
               'mb-4': pagination || showTableResume,
            })}
         >
            <div className="flex justify-between mb-4 px-4">
               <div className="flex items-end">
                  {showTableResume && pagination && (
                     <TableResume page={pageIndex} size={pagination.defaultPageSize} total={pagination.totalSize} />
                  )}
               </div>
               {/* {onExport && exportText && (
            <Button onClick={onExport} className={"flex gap-x-2 items-center text-[12px]"}>
              <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '}/>
              {exportText}
            </Button>
        )} */}
               {children && children}
            </div>
            {fetching && <div className="absolute inset-0 bg-gray-200 bg-opacity-50" />}
            <table className={classNames('w-full max-w-full divide-y divide-gray-200 table-fixed', tableClassName)}>
               <thead className="bg-gray-50 align-top">
                  {getHeaderGroups().map((headerGroup) => (
                     <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                           return (
                              <th
                                 key={header.id}
                                 {...(header.getSize() !== 51 && { style: { width: `${header.getSize()}px` } })}
                                 className="px-4 py-3 text-left text-sm font-medium text-gray-500 whitespace-nowrap overflow-hidden overflow-ellipsis relative"
                                 {...(header.column.getCanSort() && {
                                    onClick: header.column.getToggleSortingHandler(),
                                 })}
                              >
                                 {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                 {/* Add sorting icon */}

                                 {header.column.getIsSorted() === 'asc' ? (
                                    <span className="absolute inset-x-1/2 top-0">
                                       {' '}
                                       <FontAwesomeIcon icon={faSortUp} className="mr-2 text-gray-500 " />
                                    </span>
                                 ) : header.column.getIsSorted() === 'desc' ? (
                                    <span className="absolute inset-x-1/2 top-0">
                                       {' '}
                                       <FontAwesomeIcon icon={faSortDown} className="mr-2 text-gray-500 " />
                                    </span>
                                 ) : null}
                              </th>
                           )
                        })}
                     </tr>
                  ))}
               </thead>
               <tbody className="bg-white divide-y divide-gray-200 align-top">
                  {getRowModel().rows.length === 0 && fetching && (
                     <tr>
                        <td colSpan={columns.length} className="text-center px-4 py-2">
                           Cargando ...
                        </td>
                     </tr>
                  )}
                  {getRowModel().rows.length === 0 && !fetching && (
                     <tr>
                        <td colSpan={columns.length} className="text-center px-4 py-2">
                           No se encontraron elementos
                        </td>
                     </tr>
                  )}
                  {getRowModel().rows.map((row, i) => {
                     return (
                        <tr key={row.id} className={`${i % 2 !== 0 ? 'bg-gray-50' : ''} hover:bg-gray-100`}>
                           {row.getVisibleCells().map((cell) => (
                              <td
                                 key={cell.id}
                                 className={`border-none px-4 py-2 whitespace-nowrap ${
                                    !(cell.column as any)?.overflowVisible
                                       ? 'overflow-hidden overflow-ellipsis hover:whitespace-normal'
                                       : ''
                                 }`}
                              >
                                 {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                           ))}
                        </tr>
                     )
                     // prepareRow(row)
                     // return (
                     //    <tr {...row.getRowProps()} className={`${i % 2 !== 0 ? 'bg-gray-50' : ''} hover:bg-gray-100`}>
                     //       {row.cells.map((cell) => (
                     //          <td
                     //             {...cell.getCellProps()}
                     //             className={`border-none px-4 py-2 whitespace-nowrap ${
                     //                !(cell.column as any)?.overflowVisible
                     //                   ? 'overflow-hidden overflow-ellipsis hover:whitespace-normal'
                     //                   : ''
                     //             }`}
                     //          >
                     //             {cell.render('Cell')}
                     //          </td>
                     //       ))}
                     //    </tr>
                     // )
                  })}
               </tbody>
            </table>
         </div>
         {pagination && (
            <div className={classNames('flex justify-between mb-4', footerClassName)}>
               <div>
                  {showTableResume && (
                     <TableResume page={pageIndex} size={pagination.defaultPageSize} total={pagination.totalSize} />
                  )}
               </div>
               <Pagination
                  pageIndex={pageIndex}
                  nextPage={() => gotoPage(pageIndex + 1)}
                  previousPage={() => gotoPage(pageIndex - 1)}
                  gotoPage={gotoPage}
                  pageCount={getPageCount()}
               />
            </div>
         )}
      </div>
   )
}
