import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getPartnerList, GetPartnerListRequest } from '../../api/partner'
function useGetPartnerList(request: GetPartnerListRequest) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_PARTNER_LIST, request ?? ''],
      queryFn: () => getPartnerList(request),
      enabled: !!request,
   })
}
export { useGetPartnerList }
export default useGetPartnerList
