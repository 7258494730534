import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SelectAccount from '../user/SelectAccount'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getProfileThunk } from '../../features/profileSlice'
import { loadAccount } from '../../features/baseSlice'

export type AccountType = {
   code: string
   name: string
}

export const Accounts = () => {
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const profile = useAppSelector((state) => state.profile)

   useEffect(() => {
      dispatch(getProfileThunk())
   }, [dispatch])

   const selectAccount = useCallback(
      (account: AccountType) => {
         dispatch(loadAccount(account))
         navigate(`/${account.code}/dashboard`)
      },
      [dispatch, navigate],
   )

   useEffect(() => {
      if (!profile.isFetching && !profile.isPristine && profile.data) {
         if (profile.data.accounts.length === 1) {
            selectAccount(profile.data.accounts[0])
         }
      }
   }, [profile, selectAccount])

   if (profile.isFetching || profile.isPristine) {
      return <></>
   }

   return <SelectAccount onSelect={selectAccount} />
}
