import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../common/Card'
import Title from '../common/Title'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import { getCurrentValues } from '../../utils/programsUtils'
import { useGetPrograms } from '../../hooks/useGetPrograms'
import Filters, { PartnerFilterForm } from './components/Filters'
import qs from 'qs'
import { Button, Table } from 'ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@tanstack/react-query'
import { getProgramExportList } from '../../api/program'

export type Program = {
   id: number
   name: string
   level: Array<{ id: number; name: string }>
}
type Props = {}

export const Programs = (props: Props) => {
   const intl = useIntl()
   const navigate = useNavigate()
   const location = useLocation()
   const { data, status } = useGetPrograms()
   const { mutate } = useMutation({
      mutationFn: getProgramExportList,
   })

   const columns = useMemo(
      () => [
         {
            header: intl.formatMessage({ id: 'program.id' }),
            accessorKey: 'id',
            size: 50,
         },
         {
            header: intl.formatMessage({ id: 'program.planName' }),
            cell: ({ row }: { row: { original: Program } }) => {
               return (
                  <span>
                     {row.original.name}
                     <div className="text-sm text-neutral-400">{row.original.name}</div>
                  </span>
               )
            },
         },

         // {
         //    header: intl.formatMessage({ id: 'operations.partner' }),
         //    cell: ({ row }: { row: { original: Operation } }) => {
         //       return (
         //          <div>
         //             {row.original.member.firstName}
         //             <div className="text-sm text-neutral-400">{row.original.member.email}</div>
         //          </div>
         //       )
         //    },
         // },
         // {
         //    header: intl.formatMessage({ id: 'operations.accumulatedPoints' }),
         //    accessorKey: 'pointsAccumulatedRemaining',
         //    cell: (item: any) => <div className="text-right">{item.getValue()}</div>,
         // },
         // {
         //    header: intl.formatMessage({ id: 'operations.redeemedPoints' }),
         //    accessorKey: 'pointsRedeemed',
         //    cell: (item: any) => <div className="text-right">{item.getValue()}</div>,
         // },
      ],
      [intl],
   )

   const currentValues = getCurrentValues(location.search)

   const onFilter = (data: PartnerFilterForm) => {
      const request = Object.assign({}, data)
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }
   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onExport = () => {
      mutate(getCurrentValues(location.search), {
         onSuccess: (response) => {
            let excellName = `programs.xlsx`
            const blob = new Blob([response as unknown as BlobPart], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
         },
      })
   }
   const getData = () => {
      return data?.items.sort((a, b) => (a.order > b.order ? -1 : 1)) ?? []
   }

   return (
      <>
         <Title value={intl.formatMessage({ id: 'titles.programs' })} />
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               <Filters className="px-4" onSubmit={onFilter} defaultValues={currentValues} />
               <Table
                  fetching={status === 'pending'}
                  columns={columns}
                  data={getData()}
                  tableClassName="border-y"
                  footerClassName="px-4"
                  // onExport={onExport}
                  // exportText={intl.formatMessage({ id: 'program.export' })}
                  showTableResume
                  pagination={{
                     defaultPageSize: currentValues.size,
                     defaultPageIndex: currentValues.page,
                     totalSize: data?.totalSize ?? 0,
                     onPageChange: onPageChange,
                  }}
               >
                  <div className="flex justify-between gap-x-2">
                     <Button onClick={onExport} className={'flex gap-x-2 items-center text-[12px]'}>
                        <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                        {intl.formatMessage({ id: 'program.export' })}
                     </Button>
                  </div>
               </Table>
            </Card>
         </div>
      </>
   )
}
