import React from 'react'

type Props = {
   label: string
   value?: string
}

const CardItem = ({ label, value }: Props) => {
   return (
      <div className="pt-5 pr-2.5 pb-0">
         <div className="overflow-hidden inline-block flex-1">
            <div className="text-neutral-400">{label}</div>
            <div>{value}</div>
         </div>
      </div>
   )
}

export default CardItem
