import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getOperationStatus } from '../../api/operation'

function useGetOperationStatus(request: any) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_OPERATION_STATUS, request],
      queryFn: () => {
         return getOperationStatus(request)
      },
   })
}
export { useGetOperationStatus }
export default useGetOperationStatus
