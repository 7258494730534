import { Partner } from '../components/partners'
import { apiCall } from '../utils/api'

export type GetPartnerListRequest = {
   query?: string
   page?: number
   size?: number
   sort?: string[]
   active?: string
   loyaltyPlanIds?: string
   channelIds?: number[]
   levelIds?: number[]
}
interface LoyaltyPlan {
   loyaltyPlanId: number | string
   levelName: string
   registrationChannel: string
}

interface UserProfileRequest {
   firstName: string
   lastName: string
   email: string
   phoneNumber: string
   dateOfBirth: string // NotNull, formato ISO-8601 (YYYY-MM-DD)
   state: string
   municipality: string
   city: string
   addressLine: string
   zipCode: string
   countryCode: string
   countryOfResidence?: string
   hotelId?: number
   auth0UserId?: string
   maritalStatus?: string
   gender?: string
   plans: LoyaltyPlan[]
   allergies?: string // Opcional
   bloodType?: string // Opcional
   swimmingSkill?: boolean // Opcional
   status?: string // Opcional
   passion?: string // Opcional
   operaProfileSourceId?: string // Opcional
}
interface userImportRequest {
   loyaltyPlanId: number
   file: FormData | File
}
interface userStatusImportRequest {
   type: string
}
interface userEditRequest {
   id: string
   firstName: string // NotNull en Kotlin implica no permitir null.
   lastName: string
   phoneNumber: string
   maritalStatus?: string // Puede ser null en Kotlin, así que se hace opcional en TypeScript.
   dateOfBirth: string // LocalDate se mapea a Date en TypeScript.
   gender: string
   countryOfResidence: string
   addressLine: string
   countryCode: string
   city: string
   zipCode: string
   state: string
   urlRefer: string
}
export interface responseImported {
   account: string
   loyaltyPlanId: number
   path: string
   statusId: number
}
export type GetPartnerListExportRequest = {
   query: string
   page: number
   size: number
   sort: string[]
   active?: string
   loyaltyPlanIds: string
   channelIds: number[]
   levelIds: number[]
}

export async function getPartnerExportList(request: GetPartnerListExportRequest): Promise<Partner> {
   const { query, page, size, active, loyaltyPlanIds, channelIds, levelIds, sort } = request || {}
   let params = {}
   if (query) params = { ...params, query }
   if (page >= 0) params = { ...params, page }
   if (size) params = { ...params, size }
   if (active) params = { ...params, active: active === 'Activos' ? true : false }
   if (loyaltyPlanIds.length) params = { ...params, loyaltyPlanIds: loyaltyPlanIds }
   if (channelIds.length) params = { ...params, channelIds: channelIds.join(',') }
   if (levelIds.length) params = { ...params, levelIds: levelIds.join(',') }
   if (sort && sort.length) params = { ...params, sort: sort.join(',') }
   let extraHeaders = {
      Accept: '*/* , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/export`,
      method: 'GET',
      params,
      headers: extraHeaders,
      responseType: 'arraybuffer',
   })

   return result.data
}
export async function getPartnerList(
   request: GetPartnerListRequest,
): Promise<{ items: Array<Partner>; totalSize: number }> {
   const { query, page, size, active, loyaltyPlanIds, channelIds, levelIds, sort } = request || {}
   let params = {}
   if (query) params = { ...params, query }
   if (page && page >= 0) params = { ...params, page }
   if (size) params = { ...params, size }
   if (active) params = { ...params, active: active === 'Activos' ? true : false }
   if (loyaltyPlanIds) params = { ...params, loyaltyPlanIds: loyaltyPlanIds }
   if (channelIds && channelIds.length) params = { ...params, channelIds: channelIds.join(',') }
   if (levelIds && levelIds.length) params = { ...params, levelIds: levelIds.join(',') }
   if (sort && sort.length) params = { ...params, sort: sort.join(',') }
   const result = await apiCall(
      {
         url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members`,
         method: 'GET',
         params,
      },
      undefined,
      undefined,
      undefined,
   )
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}

export async function getPartner(id: string): Promise<Partner> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/${id}`,
      method: 'GET',
   })

   return result.data
}

export async function createPartner(request: UserProfileRequest): Promise<Partner> {
   const { ...data } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members`,
      method: 'POST',
      data: data,
   })

   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function importFilePartner(request: userImportRequest): Promise<responseImported> {
   const { loyaltyPlanId, file } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/loyaltyPlan/${loyaltyPlanId}/upload`,
      method: 'POST',
      data: { file: file },
      headers: {
         Accept: 'application/json, text/plain, */*',
         'Content-Type': 'multipart/form-data;',
      },
   })

   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function ImportFileStatus(request: userStatusImportRequest): Promise<any> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/import/status`,
      method: 'GET',
      params: request,
   })

   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
export async function editPartner(request: userEditRequest): Promise<any> {
   const { id, ...rest } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/${id}`,
      method: 'PUT',
      data: rest,
   })

   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
