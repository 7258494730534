import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { getCurrentValues } from '../../utils/programsUtils'
import { getProgramList } from '../../api/program'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetPrograms() {
   const location = useLocation()
   const params = getCurrentValues(location.search)

   return useQuery({
      queryKey: [QUERY_KEYS.GET_PROGRAMS, params],
      queryFn: () => getProgramList(params),
   })
}

export { useGetPrograms }
export default useGetPrograms
