import { Operation } from '../components/operations'
import { apiCall } from '../utils/api'

export type GetOperationListRequest = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: string
   from?: string
   to?: string
   operationStatus: string[]
}
export type GetOperationExportListRequest = {
   account: string
   emailRecipients?: Array<string>
   filters: {
      loyaltyPlanIds?: number
      memberId?: string
   }
}
export type requestEditCreateOperation = {
   account: string
   memberId: string
   loyaltyPlanId: number
   registationChannel: string
   operationType: string
   serviceType: string
   amount: string
   currency: string
   numberOfNights?: string
   roomsPerNight?: string
   invoiceNumber?: string
   reference?: string
   reservationDate: string
   operationDate: number
}
export type requestCreateOperation = {
   account: string
   memberId: string
   loyaltyPlanId: number
   registationChannel: string
   operationType: string
   serviceType: string
   amount: string
   currency: string
   numberOfNights?: string
   roomsPerNight?: string
   invoiceNumber?: string
   reference?: string
   reservationDate: string
   operationDate: number
   reservationTotal: number
}
export type getOperationListResponse = {
   items: Array<Operation>
   totalSize: number
}
interface operationImportRequest {
   loyaltyPlanId: number
   file: FormData | File
}
export interface responseImported {
   account: string
   loyaltyPlanId: number
   path: string
   statusId: number
}

export async function getOperationExportList(request: GetOperationExportListRequest): Promise<any> {
   let extraHeaders = {
      Accept: '*/* , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/export`,
      method: 'POST',
      data: request,
      headers: extraHeaders,
      responseType: 'arraybuffer',
   })
   return result.data
}
export async function getOperationList(request: GetOperationListRequest): Promise<getOperationListResponse> {
   const { query, page, size, loyaltyPlanIds, from, to, sort, operationStatus } = request
   let params = {}
   if (query) params = { ...params, query }
   if (page >= 0) params = { ...params, page }
   if (size) params = { ...params, size }
   if (loyaltyPlanIds) params = { ...params, loyaltyPlanIds: loyaltyPlanIds }
   if (from) params = { ...params, from: from }
   if (to) params = { ...params, to: to }
   if (operationStatus && operationStatus.length) params = { ...params, operationStatus: operationStatus.join(',') }
   if (sort && sort.length) params = { ...params, sort: sort.join(',') }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations`,
      method: 'GET',
      params,
   })
   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
export async function createOperation(request: requestEditCreateOperation): Promise<Operation> {
   const { ...data } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations`,
      method: 'POST',
      data: data,
   })
   return result.data
}
export async function editOperation(request: requestEditCreateOperation): Promise<Operation> {
   const { ...data } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/${request.memberId}`,
      method: 'PUT',
      data: data,
   })
   return result.data
}
export async function importFileOperations(request: operationImportRequest): Promise<responseImported> {
   const { loyaltyPlanId, file } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/loyaltyPlan/${loyaltyPlanId}/upload`,
      method: 'POST',
      data: { file: file },
      headers: {
         Accept: 'application/json, text/plain, */*',
         'Content-Type': 'multipart/form-data;',
      },
   })

   return result.data
}
export async function importFilePMSOperations(request: operationImportRequest): Promise<responseImported> {
   const { loyaltyPlanId, file } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/loyaltyPlan/${loyaltyPlanId}/upload/pms`,
      method: 'POST',
      data: { file: file },
      headers: {
         Accept: 'application/json, text/plain, */*',
         'Content-Type': 'multipart/form-data;',
      },
   })

   return result.data
}
export async function getOperationStatus(request: any): Promise<Array<string>> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/status`,
      method: 'GET',
   })

   return result.data
}
export async function getOperation(request: { id?: string }): Promise<Operation> {
   const { id } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/${id}`,
      method: 'GET',
   })

   return result.data
}
export async function getServiceTypeList(request: any): Promise<any> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/service-types`,
      params: request,
      method: 'GET',
   })
   if (result.data) {
      return Object.entries(result.data).map(([id, description]) => ({
         id: Number(id),
         description,
      }))
   }
   return []
}
