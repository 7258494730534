import { contract } from '../../../utils/programsUtils'
import ContractSelectField from './ContractSelectField'
import { useGetHotelList } from '../../../hooks/useGetHotelList'

type props = {
   fields: Array<contract>
}

const ContractsField = (props: props) => {
   const { fields } = props
   const { data, isLoading } = useGetHotelList({ page: 1, pageSize: 1000 })
   if (isLoading || !data) {
      return <>'...loading'</>
   }
   const drawItems = () => {
      return fields.map((item, index) => {
         const content = data?.items?.find((hotel) => hotel.id === item.hotelId)
         return (
            <tr key={item.id} className="mb-2 border-b last:border-b-0">
               <td>{content && content.name}</td>
               <td className="pt-2 ">
                  {content && <ContractSelectField item={index} hotelUri={content.code} key={content.code} />}
               </td>
            </tr>
         )
      })
   }
   return (
      <table className="table-auto w-full">
         <thead>
            <tr className="border-b ">
               <th className="text-left">Hotel</th>
               <th style={{ width: '70%' }}>Contrato</th>
            </tr>
         </thead>
         <tbody>{drawItems()}</tbody>
      </table>
   )
}

export default ContractsField
