import { useQuery } from '@tanstack/react-query'

import { getPartner } from '../../api/partner'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetPartner(id?: string) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_PARTNER, id ?? ''],
      queryFn: () => getPartner(id as string),
      enabled: !!id,
   })
}

export { useGetPartner }
export default useGetPartner
