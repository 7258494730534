import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import classNames from 'classnames'

type Props = {
   onSelect: (account: { code: string; name: string }) => void
}

const SelectAccount = ({ onSelect }: Props) => {
   const profile = useAppSelector((state) => state.profile)
   const [query, setQuery] = useState('')

   const filteredAccounts = useMemo(() => {
      const regex = new RegExp(query.trim(), 'i')
      const filteredItems = !query ? profile.data?.accounts : profile.data?.accounts.filter((i) => regex.test(i.name))
      return filteredItems
   }, [profile?.data?.accounts, query])

   function onChange(event: React.ChangeEvent<HTMLInputElement>) {
      setQuery(event.target.value)
   }

   function selectAccountHandler(account: { code: string; name: string }) {
      onSelect(account)
   }

   return (
      <div className="flex items-center justify-center h-screen">
         <div className="lg:w-6/12 md:w-8/12 w-full border rounded shadow">
            <div className="p-4 bg-gray-200 rounded-t-sm">Elige una cuenta</div>
            <div className="border-b border-gray-100">
               <input
                  type="text"
                  className="w-full p-4 outline-none"
                  placeholder="Buscar cuenta"
                  value={query}
                  onChange={onChange}
                  autoComplete="off"
                  autoFocus
               />
            </div>
            <ul
               className={classNames('mb-1 overflow-auto', {
                  'border-neutral-200': filteredAccounts?.length === 0,
                  'h-[545px]': filteredAccounts && filteredAccounts?.length > 15,
               })}
            >
               {query && (!filteredAccounts || filteredAccounts.length === 0) && (
                  <li className="p-4 text-gray-700">No se encontraron cuentas</li>
               )}
               {filteredAccounts?.map((account) => {
                  return (
                     <li key={account.code} className="border-b  border-gray-100 last:border-none">
                        <button
                           type="button"
                           className="p-4 text-gray-700 hover:bg-gray-100 w-full flex items-center justify-start gap-x-4"
                           onClick={() => selectAccountHandler(account)}
                        >
                           <div className="bg-green-600 h-8 w-8 flex items-center justify-center text-white rounded-full">
                              {account.name.match(/\b\w/g)?.join('').toUpperCase()}
                           </div>
                           {account.name}
                        </button>
                     </li>
                  )
               })}
            </ul>
         </div>
      </div>
   )
}

export default SelectAccount
