import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountType } from '../components/accounts'

export type BaseState = {
   account?: AccountType
   locale: string
}

const initialState: BaseState = {
   locale: '',
}

export const baseSlice = createSlice({
   name: 'base',
   initialState,
   reducers: {
      loadAccount: (state, action: PayloadAction<AccountType>) => {
         state.account = action.payload
      },
      loadLocale: (state, action: PayloadAction<string>) => {
         state.locale = action.payload
      },
   },
})

export const { loadAccount, loadLocale } = baseSlice.actions

export default baseSlice.reducer
