import React from 'react'
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form'
import Card from '../../../common/Card'
import { Button } from 'ui'
import { useIntl } from 'react-intl'
import { Input, Select } from 'ui/fields'
import { emailRegex } from 'utils/validation'
import { useGetCountries } from '../../../../hooks/useGetCountries'
import PhoneCodeSelector from '../PhoneCodeSelector'
import BirthdatePicker, { Birthdate } from '../BirthdatePicker'
import { AdditionalInformationType, plans } from '../../../../api/program'
import LevelIdField from '../LevelIdField'
import { Channel } from '../..'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

export type Inputs = {
   firstName: string
   lastName: string
   email: string
   phoneNumber: string
   dateOfBirth: string
   registrationChannel?: string
   loyaltyPlans: Array<{
      loyaltyPlanId: string
      level: string
      registrationChannel: string
   }>
   maritalStatus?: string
   operaProfileSourceId?: string
   passion?: string
   allergies?: string
   bloodType?: string
   swimmingSkill?: string
   countryCode: string
   state: string
   countryOfResidence: string
   municipality: string
   gender: string
   zipCode: string
   addressLine: string
   city: string
}
type Props = {
   defautlValues?: Inputs
   onSubmit: (data: Inputs) => void
   plans?: {
      items: Array<plans>
      totalSize: number
   }
   channels?: {
      items: Array<Channel>
      totalSize: number
   }
   onCancel: () => void
   isloading: boolean
}
function Forms(props: Props) {
   const { defautlValues, onSubmit, plans, channels, onCancel, isloading } = props
   const { data, isLoading } = useGetCountries()
   const intl = useIntl()
   const {
      register,
      handleSubmit,
      control,
      setValue,
      formState: { errors, dirtyFields },
   } = useForm<Inputs>({
      mode: 'onChange',
      defaultValues: defautlValues
         ? defautlValues
         : {
              loyaltyPlans: [
                 {
                    loyaltyPlanId: plans?.totalSize === 1 ? plans?.items[0].id.toString() : '',
                    level: '',
                    registrationChannel: channels?.totalSize === 1 ? channels?.items[0].name : '',
                 },
              ],
              countryCode: 'MX',
           },
   })
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'loyaltyPlans',
   })
   const plansForm = useWatch({ control, name: 'loyaltyPlans' })

   const selectedPlans = plansForm.map((formItem) => {
      if (!plans?.items || !formItem.loyaltyPlanId) return null
      return plans.items.find((plan) => plan.id === Number(formItem.loyaltyPlanId)) || null
   })

   const parseBirthdate = (value: string): Birthdate => {
      const [year, month, day] = value.split('-')
      return { day, month, year }
   }
   const formatBirthdate = (birthdate: Birthdate): string => {
      return `${birthdate.year}-${birthdate.month}-${birthdate.day}`
   }
   const validateOptions = (id: number) => {
      const plan = plansForm?.find((i: any) => i?.loyaltyPlanId === id)
      return plan ? true : false
   }
   const renderAdditionalFields = (additionalFields: AdditionalInformationType[]) => {
      return (
         <div className="flex flex-wrap">
            {additionalFields.map((field, index) => (
               <div key={field.name} className="w-1/2 mb-4 px-2">
                  {field.type === 'String' && (
                     <Input
                        boldLabel={false}
                        type="text"
                        autoFocus={index === 0}
                        label={(<> {field.required ? getLabel(field.label) : field.label}</>) as unknown as string}
                        {...register(field.name as keyof Inputs, {
                           required: field.required ? intl.formatMessage({ id: `partner.errors.required` }) : false,
                        })}
                        error={errors?.[field.name as keyof Inputs]?.message}
                        isTouched={dirtyFields?.[field.name as keyof Inputs] as boolean}
                        containerClassName="w-full text-gray-700"
                     />
                  )}
                  {field.type === 'select' && (
                     <Controller
                        name={field.name as keyof Inputs}
                        control={control}
                        rules={{
                           required: field.required ? intl.formatMessage({ id: `partner.errors.required` }) : false,
                        }}
                        render={({ field: { value, onChange } }) => (
                           <Select
                              boldLabel={false}
                              label={
                                 (<> {field.required ? getLabel(field.label) : field.label}</>) as unknown as string
                              }
                              value={typeof value === 'string' || typeof value === 'number' ? value : ''}
                              onChange={onChange}
                              error={errors?.[field.name as keyof Inputs]?.message}
                              isTouched={dirtyFields?.[field.name as keyof Inputs] as boolean}
                              showErrorText={field.required}
                              className="w-full text-gray-600"
                           >
                              <Select.Option value="">Seleccionar</Select.Option>
                              {field.values.map((optionValue) => (
                                 <Select.Option key={optionValue} value={optionValue}>
                                    {optionValue}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     />
                  )}
                  {field.type === 'boolean' && (
                     <Controller
                        name={field.name as keyof Inputs}
                        control={control}
                        rules={{
                           required: field.required ? intl.formatMessage({ id: `partner.errors.required` }) : false,
                        }}
                        render={({ field: { value, onChange } }) => (
                           <Select
                              boldLabel={false}
                              label={
                                 (<> {field.required ? getLabel(field.label) : field.label}</>) as unknown as string
                              }
                              value={typeof value === 'string' || typeof value === 'number' ? value : ''}
                              onChange={onChange}
                              error={errors?.[field.name as keyof Inputs]?.message}
                              isTouched={dirtyFields?.[field.name as keyof Inputs] as boolean}
                              showErrorText={field.required}
                              className="w-full text-gray-700"
                           >
                              <Select.Option value="">Seleccionar</Select.Option>
                              {field.values.map((optionValue) => (
                                 <Select.Option key={optionValue} value={optionValue}>
                                    {optionValue}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     />
                  )}
               </div>
            ))}
         </div>
      )
   }
   const getLabel = (field: string) => {
      return (
         <>
            {field}
            <FontAwesomeIcon icon={faCircle} size="xs" className={classNames('text-green-500 ml-1')} />
         </>
      ) as unknown as string
   }
   if (isLoading) {
      return (
         <div className="flex justify-center items-center h-96">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
         </div>
      )
   }
   return (
      <div className='className="flex flex-col gap-y-4 mx-4'>
         <p className="pb-1 font-semibold">
            <FontAwesomeIcon icon={faCircle} size="xs" className={classNames('text-green-500 ml-1')} /> :{' '}
            {intl.formatMessage({ id: `partner.form.fields.required` })}
         </p>
         <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className="p-5">
                  <h3 className="font-semibold text-lg mb-6 text-gray-700">
                     {intl.formatMessage({ id: `partner.tittle.personalInformation` })}
                  </h3>
                  <div className="flex gap-x-8 w-full">
                     <Input
                        type="text"
                        autoFocus
                        label={getLabel(intl.formatMessage({ id: `partner.form.firstName` }))}
                        {...register('firstName', {
                           required: intl.formatMessage({ id: `partner.errors.firstName` }),
                        })}
                        boldLabel={false}
                        error={errors?.firstName?.message}
                        isTouched={dirtyFields.firstName}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                     <Input
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.lastName` }))}
                        {...register('lastName', {
                           required: intl.formatMessage({ id: `partner.errors.lastName` }),
                        })}
                        boldLabel={false}
                        error={errors?.lastName?.message}
                        isTouched={dirtyFields.lastName}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                  </div>
                  <div className="flex gap-x-8 w-full">
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.email` }))}
                        {...register('email', {
                           required: intl.formatMessage({ id: `partner.errors.email` }),
                           pattern: {
                              value: emailRegex,
                              message: intl.formatMessage({ id: 'partner.errors.emailFormat' }),
                           },
                        })}
                        error={errors?.email?.message}
                        isTouched={dirtyFields.email}
                        containerClassName="mb-4 w-full text-gray-700"
                     />

                     <Input
                        boldLabel={false}
                        type="number"
                        label={getLabel(intl.formatMessage({ id: `partner.form.phonenumber` }))}
                        {...register('phoneNumber', {
                           required: intl.formatMessage({ id: 'partner.errors.phoneNumber' }),
                        })}
                        error={errors?.phoneNumber?.message || errors?.countryCode?.message}
                        isTouched={dirtyFields.phoneNumber || dirtyFields.countryCode}
                        containerClassName="mb-4 w-full text-gray-700"
                        inputGroupAling="left"
                        inputGroup={
                           data &&
                           data.length && (
                              <PhoneCodeSelector
                                 countries={data}
                                 control={control}
                                 register={register}
                                 error={errors?.countryCode?.message}
                                 isTouched={dirtyFields.countryCode}
                              />
                           )
                        }
                        helpText={
                           <div className="flex">
                              <div className="w-[110px]">{intl.formatMessage({ id: 'partner.form.areaCode' })}</div>
                              <div>{intl.formatMessage({ id: 'partner.form.phone.label.info' })}</div>
                           </div>
                        }
                     />
                  </div>
                  <div className="flex gap-x-8 w-full">
                     <Controller
                        name="dateOfBirth"
                        control={control}
                        rules={{
                           required: intl.formatMessage({ id: 'partner.errors.birthday' }),
                           validate: (value) => {
                              const [year, month, day] = value.split('-')
                              if (!day || !month || !year) {
                                 return intl.formatMessage({ id: 'partner.errors.birthday.invalid' })
                              }
                              return true
                           },
                        }}
                        render={({ field }) => {
                           const birthdateValue = field.value
                              ? parseBirthdate(field.value)
                              : { day: '', month: '', year: '' }

                           return (
                              <div className={`mb-4 w-full ${errors?.dateOfBirth?.message ? 'has-error' : ''}`}>
                                 <label className="block mb-2 text-gray-700">
                                    {getLabel(intl.formatMessage({ id: `partner.form.birthday` }))}
                                 </label>
                                 <BirthdatePicker
                                    className="mb-4 w-full pr-4"
                                    value={birthdateValue}
                                    onChange={(newBirthdate) => field.onChange(formatBirthdate(newBirthdate))}
                                 />
                                 {errors?.dateOfBirth?.message && (
                                    <div className="text-red-500 error-block text-[12px] mt-1">
                                       {errors?.dateOfBirth?.message}
                                    </div>
                                 )}
                              </div>
                           )
                        }}
                     />
                     <Controller
                        name={`gender`}
                        control={control}
                        rules={{
                           required: intl.formatMessage({ id: 'partner.errors.required' }),
                           validate: (value) => {
                              return value ? true : intl.formatMessage({ id: 'partner.errors.required' })
                           },
                        }}
                        render={({ field: { value, onChange } }) => (
                           <Select
                              boldLabel={false}
                              label={getLabel(intl.formatMessage({ id: `partner.form.gender` }))}
                              value={value || ''}
                              onChange={(i: string) => onChange(i || '')}
                              error={errors?.gender?.message}
                              showErrorText={true}
                              className="mb-4 w-full text-gray-700"
                              isTouched={dirtyFields.gender}
                           >
                              <Select.Option value="">Seleccionar</Select.Option>
                              {['MALE', 'FEMALE'].map((gender, index) => (
                                 <Select.Option key={gender} value={gender}>
                                    {intl.formatMessage({ id: `partner.gender.${gender}` })}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     />
                  </div>
                  <div className="flex gap-x-8 w-full">
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.countryOfResidence` }))}
                        {...register('countryOfResidence', {
                           required: intl.formatMessage({ id: `partner.errors.countryOfResidence` }),
                        })}
                        error={errors?.countryOfResidence?.message}
                        isTouched={dirtyFields.countryOfResidence}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.state` }))}
                        {...register('state', { required: intl.formatMessage({ id: `partner.errors.state` }) })}
                        error={errors?.state?.message}
                        isTouched={dirtyFields.state}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                  </div>
                  <div className="flex gap-x-8 w-full">
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.city` }))}
                        {...register('city', {
                           required: intl.formatMessage({ id: `partner.errors.city` }),
                        })}
                        error={errors?.city?.message}
                        isTouched={dirtyFields.city}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.municipality` }))}
                        {...register('municipality', {
                           required: intl.formatMessage({ id: `partner.errors.municipality` }),
                        })}
                        error={errors?.municipality?.message}
                        isTouched={dirtyFields.municipality}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                  </div>
                  <div className="flex gap-x-8 w-full">
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.municipality` }))}
                        {...register('addressLine', {
                           required: intl.formatMessage({ id: `partner.errors.address` }),
                        })}
                        error={errors?.addressLine?.message}
                        isTouched={dirtyFields.addressLine}
                        containerClassName="mb-4 w-full text-gray-700"
                     />
                     <Input
                        boldLabel={false}
                        type="text"
                        label={getLabel(intl.formatMessage({ id: `partner.form.ZipCode` }))}
                        {...register('zipCode', { required: intl.formatMessage({ id: `partner.errors.ZipCode` }) })}
                        error={errors?.zipCode?.message}
                        isTouched={dirtyFields.zipCode}
                        containerClassName="mb-4 w-full text-gray-700 pr-4"
                     />
                  </div>
               </div>
               <div className="border mt-3 "></div>
               <div className="p-5">
                  <h3 className="font-semibold text-lg mb-6 text-gray-700">
                     {intl.formatMessage({ id: 'partner.tittle.loyaltyPlans' })}
                  </h3>
                  {fields.map((item, index, arry) => (
                     <React.Fragment key={item.id}>
                        <div className="flex space-x-5 w-full items-center">
                           <Controller
                              name={`loyaltyPlans.${index}.loyaltyPlanId`}
                              control={control}
                              rules={{
                                 required: intl.formatMessage({ id: 'partner.errors.loyaltyplanId' }),
                                 validate: (value) => {
                                    return value ? true : intl.formatMessage({ id: 'partner.errors.loyaltyplanId' })
                                 },
                              }}
                              render={({ field: { value, onChange } }) => (
                                 <Select
                                    label={getLabel(intl.formatMessage({ id: `partner.form.programs` }))}
                                    value={parseInt(value) || value || ''}
                                    onChange={(i: string) => {
                                       setValue(`loyaltyPlans.${index}`, {
                                          loyaltyPlanId: i,
                                          level: '',
                                          registrationChannel: '',
                                       })

                                       onChange(parseInt(i))
                                    }}
                                    error={errors?.loyaltyPlans?.[index]?.loyaltyPlanId?.message}
                                    showErrorText={true}
                                    className="mb-4 w-full text-gray-700"
                                    boldLabel={false}
                                    isTouched={dirtyFields.loyaltyPlans?.[index]?.loyaltyPlanId}
                                 >
                                    <Select.Option value="">Seleccionar</Select.Option>
                                    {plans &&
                                       plans.items.map((plan) => (
                                          <Select.Option
                                             key={plan.id}
                                             value={plan.id}
                                             disabled={validateOptions(plan.id)}
                                          >
                                             {plan.name}
                                          </Select.Option>
                                       ))}
                                 </Select>
                              )}
                           />
                           <LevelIdField
                              index={index}
                              control={control}
                              errors={errors}
                              dirtyFields={dirtyFields}
                              loyaltyplanId={item?.loyaltyPlanId}
                              setValue={setValue}
                              getLabel={getLabel}
                           />

                           <Controller
                              name={`loyaltyPlans.${index}.registrationChannel`}
                              control={control}
                              rules={{
                                 required: intl.formatMessage({ id: 'partner.errors.registrationChannel' }),
                                 validate: (value) => {
                                    return value ? true : intl.formatMessage({ id: 'partner.errors.loyaltyplanId' })
                                 },
                              }}
                              render={({ field: { value, onChange } }) => (
                                 <Select
                                    boldLabel={false}
                                    label={getLabel(intl.formatMessage({ id: `partner.form.channel` }))}
                                    value={value || ''}
                                    onChange={(i: string) => onChange(i)}
                                    error={errors?.loyaltyPlans?.[index]?.registrationChannel?.message}
                                    showErrorText={true}
                                    className="mb-4 w-full text-gray-700"
                                    isTouched={dirtyFields.loyaltyPlans?.[index]?.registrationChannel}
                                 >
                                    <Select.Option value="">Seleccionar</Select.Option>
                                    {channels &&
                                       channels.items.map((plan) => (
                                          <Select.Option key={plan.id} value={plan.name}>
                                             {plan.name}
                                          </Select.Option>
                                       ))}
                                 </Select>
                              )}
                           />
                           {fields.length > 1 && (
                              <span className="cursor-pointer" onClick={() => remove(index)}>
                                 <FontAwesomeIcon icon={faTrash} className={'text-gray-500 text '} />
                              </span>
                           )}
                        </div>
                        {index === fields.length - 1 && (
                           <Button
                              type="button"
                              bsStyle="default"
                              className="flex gap-x-2 items-center text-[12px]"
                              disabled={fields.length === plans?.items.length}
                              onClick={() =>
                                 append({
                                    loyaltyPlanId: '',
                                    level: '',
                                    registrationChannel: '',
                                 })
                              }
                           >
                              <FontAwesomeIcon icon={faPlus} className={'text-gray-500 text '} />
                              {intl.formatMessage({ id: 'partner.form.add' })}
                           </Button>
                        )}
                     </React.Fragment>
                  ))}
               </div>
               {selectedPlans.map((plan, planIndex) => {
                  if (!plan) return null
                  const additionalFields = plan.additionalInformationTypes || []
                  if (!additionalFields.length) {
                     return null
                  }
                  return (
                     <React.Fragment key={planIndex}>
                        <div className="border mt-3 "></div>
                        <div className="p-5">
                           <h3 className="font-semibold text-lg mb-6 text-gray-700">
                              {intl.formatMessage({ id: `partner.title.additionalInformation` }, { plan: plan?.name })}
                           </h3>
                           {renderAdditionalFields(additionalFields)}
                        </div>
                     </React.Fragment>
                  )
               })}
               <div className="border mt-3 "></div>
               <div className="flex justify-start gap-x-4 p-5">
                  <Button
                     type="submit"
                     bsStyle="default"
                     buttonStyle={{
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        color: 'white',
                        backgroundColor: 'rgb(217 119 6 / var(--tw-bg-opacity))',
                     }}
                     disabled={isloading}
                  >
                     Aceptar
                  </Button>
                  <Button bsStyle="default" onClick={onCancel}>
                     Cancelar
                  </Button>
               </div>
            </form>
         </Card>
      </div>
   )
}

export default Forms
