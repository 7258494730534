import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getContractList } from '../../api/contracts'

function useGetContractList(request: string) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_CONTRACT_LIST, request],
      queryFn: () => getContractList({ uri: request }),
      enabled: !!request,
   })
}

export { useGetContractList }
export default useGetContractList
