import { Channel } from '../components/partners'
import { apiCall } from '../utils/api'
export type GetChannelListRequest = {
   page: number
   pageSize: number
}
export async function getChannelList(
   request?: GetChannelListRequest,
): Promise<{ items: Array<Channel>; totalSize: number }> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/channels`,
      method: 'GET',
   })

   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
