import {
   faAddressCard,
   faBookmark,
   faCircleDollarToSlot,
   faGaugeHigh,
   faHeart,
   faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { ReactNode, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { AppContext } from './Layout'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../store/hooks'

type Props = {}

export const Logo = () => (
   <>
      <div className="fa-stack fa-sm">
         <FontAwesomeIcon icon={faBookmark} className="fa-stack-2x" />
         <FontAwesomeIcon icon={faHeart} className="fa-stack-1x text-amber-500 !h-2.5" />
      </div>
      <span className="text-xl font-bold ml-1">Loyalty Center</span>
   </>
)

const MenuItem = ({ children, href, icon }: { children?: ReactNode; href: string; icon?: React.ReactNode }) => {
   return (
      <NavLink
         to={href}
         className={({ isActive }) =>
            classNames('block cursor-pointer py-4 transition text-zinc-600 text-[16px]', {
               'bg-zinc-600/10': isActive,
               'hover:bg-zinc-600/5': !isActive,
               'px-6': !icon,
               'relative px-14': icon,
            })
         }
      >
         {icon && <div className="absolute top-5 left-6 flex items-center text-amber-500">{icon}</div>}
         {children}
      </NavLink>
   )
}

const SideMenu = (props: Props) => {
   const intl = useIntl()
   const { isMenuOpen, toggleMenu } = useContext(AppContext)
   const base = useAppSelector((state) => state.base)

   return (
      <div
         className={classNames('fixed lg:block inset-0', {
            hidden: !isMenuOpen,
            'z-30': isMenuOpen,
         })}
      >
         {isMenuOpen && (
            <div className="fixed lg:hidden inset-0 bg-black/40 backdrop-blur-sm" onClick={toggleMenu}></div>
         )}
         <div
            className={classNames(
               'fixed lg:block inset-0 w-[17rem] h-full bg-white overflow-y-auto lg:overflow-y-visible z-30',
            )}
         >
            <div className="bg-amber-500 text-white h-16 flex items-center lg:px-6">
               <Logo />
            </div>
            <div className="lg:border-r lg:border-zinc-900/10 lg:overflow-y-auto lg:pb-8 lg:pt-4 lg:h-full bg-white">
               <ul>
                  <li>
                     <MenuItem href={`${base.account?.code}/dashboard`} icon={<FontAwesomeIcon icon={faGaugeHigh} />}>
                        {intl.formatMessage({ id: 'titles.dashboard' })}
                     </MenuItem>
                  </li>
                  <li>
                     <MenuItem href={`${base.account?.code}/programs`} icon={<FontAwesomeIcon icon={faAddressCard} />}>
                        {intl.formatMessage({ id: 'titles.programs' })}
                     </MenuItem>
                  </li>
                  <li>
                     <MenuItem href={`${base.account?.code}/partners`} icon={<FontAwesomeIcon icon={faUser} />}>
                        {intl.formatMessage({ id: 'titles.partners' })}
                     </MenuItem>
                  </li>
                  <li>
                     <MenuItem
                        href={`${base.account?.code}/operations`}
                        icon={<FontAwesomeIcon icon={faCircleDollarToSlot} />}
                     >
                        {intl.formatMessage({ id: 'titles.operations' })}
                     </MenuItem>
                  </li>
               </ul>
            </div>
         </div>
      </div>
   )
}

export default SideMenu
