import { useQuery } from '@tanstack/react-query'

import { getLevelList, GetLevelListRequest } from '../../api/level'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetLevelList(request?: GetLevelListRequest) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_LEVEL_LIST, request],
      queryFn: () => getLevelList(request),
   })
}

export { useGetLevelList }
export default useGetLevelList
