import { useQuery } from '@tanstack/react-query'

import { getChannelList } from '../../api/channel'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetChannelList() {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_CHANNEL_LIST],
      queryFn: () => getChannelList(),
   })
}

export { useGetChannelList }
export default useGetChannelList
