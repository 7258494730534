import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { useAppSelector } from '../../store/hooks'
import { getCountryList } from '../../api/countries'

function useGetCountries() {
   const auth = useAppSelector((state) => state.authToken)
   const base = useAppSelector((state) => state.base)

   return useQuery({
      queryKey: [QUERY_KEYS.GET_HOTEL_LIST],
      queryFn: () => getCountryList({ account: base.account?.code, locale: base.locale }, auth.token),
      enabled: !!(base && base.account && auth.token),
   })
}

export { useGetCountries }
export default useGetCountries
