import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { Button, Table } from 'ui'
import { useGetPartnerList } from '../../../hooks/useGetPartnerList'
import { useNavigate } from 'react-router-dom'
type Props = {
   currentValues: any
   onPageChange: (p: any) => any
   onSortChange: (p: any) => any
   onExport: () => any
   columns: any
   defaultProgram?: number
}

function TablePartners(props: Props) {
   const { currentValues, onPageChange, columns, defaultProgram, onSortChange } = props
   const navigate = useNavigate()
   const { loyaltyPlanIds, ...rest } = currentValues
   const { data: partnerList, status } = useGetPartnerList({
      ...(loyaltyPlanIds.length ? { loyaltyPlanIds } : { loyaltyPlanIds: defaultProgram }),
      ...rest,
   })
   const intl = useIntl()
   const onImport = () => {
      navigate(`import`)
   }
   return (
      <>
         <Table
            fetching={status === 'pending'}
            columns={columns}
            data={partnerList?.items ?? []}
            tableClassName="border-y"
            footerClassName="px-4"
            exportText={intl.formatMessage({ id: 'partner.export' })}
            showTableResume
            pagination={{
               defaultPageSize: currentValues.size,
               defaultPageIndex: currentValues.page,
               totalSize: partnerList?.totalSize ?? 0,
               onPageChange: onPageChange,
            }}
            sorting={{
               sorting:
                  currentValues.sort && currentValues.sort.length
                     ? [{ id: currentValues.sort[0], desc: currentValues.sort[1] === 'desc' }]
                     : null,
               onSortChange: onSortChange,
            }}
         >
            <div className="flex justify-between gap-x-2">
               <Button onClick={onImport} className={'flex gap-x-2 items-center text-[12px]'}>
                  <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'partner.import' })}
               </Button>
               {/* <Button onClick={onExport} className={'flex gap-x-2 items-center text-[12px]'}>
                  <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'partner.export' })}
               </Button> */}
            </div>
         </Table>
      </>
   )
}

export default TablePartners
