import React, { createContext, useContext } from 'react'
import DateRangeSelector from './DateRangeSelector/DateRangeSelector'
import classNames from 'classnames'

type Props = {
   value?: { startDate: string; endDate: string; dateType: string }
   onChange: (value: any) => void
   children: React.ReactNode
   placeholder?: string
}

type ContextType = {
   name: string
   startDate?: string
   endDate?: string
   dateType?: string
   placeholder?: string
   onDateTypeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
   onApply: (startDate: string, endDate: string) => void
}

const DateTypeSelectorContext = createContext<ContextType>({} as ContextType)

const DateTypeSelector = ({ value, placeholder, onChange, children }: Props) => {
   const startDate = value?.startDate
   const endDate = value?.endDate
   const dateType = value?.dateType

   const onDateTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
         dateType: e.target.value,
         startDate: startDate,
         endDate: endDate,
      })
   }

   const onApply = (startDate: string, endDate: string) => {
      onChange({
         dateType,
         startDate: startDate,
         endDate: endDate,
      })
   }

   return (
      <DateTypeSelectorContext.Provider
         value={{
            name: 'dateType',
            startDate,
            endDate,
            dateType,
            onDateTypeChange,
            onApply,
            placeholder,
         }}
      >
         <div className="w-80">{children}</div>
      </DateTypeSelectorContext.Provider>
   )
}

type RangeProps = {
   type: string
   className?: string
   title?: string
}

const Range = ({ type, className, title }: RangeProps) => {
   const { name, onDateTypeChange, onApply, startDate, endDate, dateType, placeholder } =
      useContext(DateTypeSelectorContext)
   if (type === 'any') {
      return (
         <div className="px-4 py-1">
            <label className="flex items-center cursor-pointer gap-2">
               <input type="radio" name={name} value={'any'} checked={dateType === 'any'} onChange={onDateTypeChange} />
               {title}
            </label>
         </div>
      )
   }
   return (
      <div className={classNames('px-4 py-1', className)}>
         <label className="flex items-center cursor-pointer gap-2">
            <input type="radio" name={name} value={type} checked={dateType === type} onChange={onDateTypeChange} />
            {title}
         </label>
         {dateType === type && (
            <div className="my-2 ml-4">
               <DateRangeSelector
                  from={startDate}
                  to={endDate}
                  onChange={onApply}
                  placeholder={placeholder}
                  monthsShown={1}
               />
            </div>
         )}
      </div>
   )
}
DateTypeSelector.Range = Range

export default DateTypeSelector
