import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from './Header'
import SideMenu from './SideMenu'
import { createContext, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useAppSelector } from '../store/hooks'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
type AppContextType = {
   isMenuOpen: boolean
   toggleMenu: () => void
}
export const AppContext = createContext<AppContextType>({
   isMenuOpen: false,
   toggleMenu: () => {},
})

type Props = {}

export const Layout = (props: Props) => {
   const [isMenuOpen, setIsMenuOpen] = useState(false)
   const { pathname } = useLocation()
   const navigate = useNavigate()
   const auth = useAppSelector((state) => state.authToken)
   useEffect(() => {
      setIsMenuOpen(false)
      document.body.classList.remove('overflow-hidden', 'lg:overflow-auto')
      if (!auth.isPristine) {
         if (!auth || !auth.isAuthenticated) {
            return navigate(`/accounts`)
         }
      }
   }, [pathname, auth, navigate])

   function toggleMenu() {
      setIsMenuOpen((current) => {
         if (!current) {
            document.body.classList.add('overflow-hidden', 'lg:overflow-auto')
         } else {
            document.body.classList.remove('overflow-hidden', 'lg:overflow-auto')
         }

         return !current
      })
   }
   return (
      <>
         <AppContext.Provider value={{ isMenuOpen, toggleMenu }}>
            <Header onToggleMenu={toggleMenu} />
            <div className={classNames('h-full', { 'lg:ml-[17rem]': auth && auth?.isAuthenticated })}>
               {auth && auth.isAuthenticated && <SideMenu />}
               <div className="relative h-full pt-16 bg-gray-100">
                  <Outlet />
                  <ToastContainer />
               </div>
            </div>
         </AppContext.Provider>
      </>
   )
}
