import { JwtPayload, jwtDecode } from 'jwt-decode'

export const isValidAccessToken = (accesToken?: string | null) => {
   if (!accesToken || accesToken === 'undefined' || accesToken === null || accesToken === '') {
      return false
   }

   const tokenDecoded = jwtDecode<JwtPayload>(accesToken)
   const expiredDate = tokenDecoded && tokenDecoded.exp ? new Date(tokenDecoded.exp * 1000) : null
   let validationDate = new Date()

   validationDate.setMinutes(validationDate.getMinutes() + 5)

   return expiredDate && validationDate.getTime() < expiredDate.getTime()
}

export const save = (name: string, value: string, days = 15) => {
   let expires

   if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
   } else {
      expires = ''
   }
   document.cookie = name + '=' + value + expires + ';path=/'
}

export const load = (name: string) => {
   let nameEQ = name + '='
   let ca = document.cookie.split(';')
   for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
   }
   return null
}

export const remove = (name: string) => {
   save(name, '', -1)
}
