import { apiCall } from '../utils/api'

export async function getPoints(id: string): Promise<{
   memberId: number
   points: number
}> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/members/${id}/points`,
      method: 'GET',
   })

   return result.data
}
