import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFileCirclePlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, TextSpinner } from 'ui'
import { useGetPartner } from '../../hooks/useGetPartner'
import History from './components/ActivityHistory'
import Card from '../common/Card'
import CardItem from './components/CardItem'
import { useAppSelector } from '../../store/hooks'
import { DateTime } from 'luxon'
import Title from '../common/Title'
import { useMutation } from '@tanstack/react-query'
import { sendConfirmation } from '../../api/profile'
import { toast } from 'react-toastify'

type Props = {}

const Partner = (props: Props) => {
   const intl = useIntl()
   const { id, accountCode } = useParams()
   const { locale } = useAppSelector((state) => state.base)
   const { data: partner, status: partnerStatus } = useGetPartner(id)
   const navigate = useNavigate()
   const [isOpen, setIsOpen] = useState(false)

   const { mutate } = useMutation({
      mutationFn: sendConfirmation,
   })

   function sendConfimationEvent() {
      if (id) {
         mutate(id, {
            onSuccess: () => {
               toast.success(intl.formatMessage({ id: 'partner.confirmation.success' }))
               setIsOpen(false)
            },
            onError: () => {
               toast.success(intl.formatMessage({ id: 'partner.confirmation.fail' }))
            },
         })
      }
   }

   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'partners.tittle.detail' })}
         >
            {partner && (
               <Button
                  onClick={() => {
                     navigate(`/${accountCode}/operations/create?partnerId=${id}`)
                  }}
                  className="flex gap-x-2 items-center text-[12px] mr-4"
               >
                  <FontAwesomeIcon icon={faFileCirclePlus} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'operations.create' })}
               </Button>
            )}
         </Title>
         {partnerStatus === 'pending' ? (
            <div className="absolute inset-0 bg-neutral-400 opacity-25 flex items-center justify-center">
               <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            </div>
         ) : null}
         {partner ? (
            <div className="flex justify-between m-4">
               <div>
                  <h2 className="text-3xl mb-1">{`${partner.firstName} ${partner.lastName}`}</h2>

                  <div className="text-neutral-400">
                     {partner.createdAt &&
                        intl.formatMessage(
                           { id: 'partner.from' },
                           { date: DateTime.fromISO(partner.createdAt, { locale }).toFormat('EEEE, dd MMMM yyyy') },
                        )}
                  </div>
               </div>
               <div className="text-right">
                  <h2 className="text-3xl mb-1">{partner.externalId}</h2>
                  <div className="text-neutral-400">ID de socio</div>
               </div>
            </div>
         ) : (
            <div className="m-4">
               <h2 className="text-3xl mb-4">
                  <TextSpinner
                     style={{
                        width: '50%',
                        color: 'transparent',
                        lineHeight: '1.0667em',
                     }}
                  >
                     .
                  </TextSpinner>
               </h2>

               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
            </div>
         )}
         <div className="flex flex-col md:flex-row gap-x-4 mx-4">
            <div className="basis-8/12">
               <Card>
                  <Card.Title>
                     <div className="flex justify-between gap-x-2  items-center">
                        {intl.formatMessage({ id: 'partner.information' })}
                        <div className="flex justify-between gap-x-2">
                           <Button
                              onClick={() => {
                                 setIsOpen(true)
                              }}
                              className="flex gap-x-2 items-center text-[12px] mr-4"
                           >
                              {intl.formatMessage({ id: 'partner.confirmation.send' })}
                           </Button>
                           <Button
                              onClick={() => {
                                 navigate(`/${accountCode}/partners/${id}/edit`)
                              }}
                              className="flex gap-x-2 items-center text-[12px] mr-4"
                           >
                              {intl.formatMessage({ id: 'partner.edit' })}
                           </Button>
                        </div>
                     </div>
                  </Card.Title>
                  <div className="px-4 pb-6 relative">
                     <div className="grid grid-cols-2 gap-4">
                        <CardItem label={intl.formatMessage({ id: 'partner.name' })} value={partner?.firstName} />
                        <CardItem label={intl.formatMessage({ id: 'partner.lastName' })} value={partner?.lastName} />
                        <CardItem label={intl.formatMessage({ id: 'partner.email' })} value={partner?.email} />
                        <CardItem label={intl.formatMessage({ id: 'partner.phone' })} value={partner?.phoneNumber} />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.status' })}
                           {...(partner &&
                              partner.maritalStatus && {
                                 value: intl.formatMessage({ id: `partner.status.${partner.maritalStatus}` }),
                              })}
                        />
                        <CardItem label={intl.formatMessage({ id: 'partner.birthday' })} value={partner?.dateOfBirth} />
                        {
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.sex' })}
                              {...(partner &&
                                 partner?.gender && {
                                    value: intl.formatMessage({ id: `partner.sex.${partner.gender}` }),
                                 })}
                           />
                        }

                        <CardItem
                           label={intl.formatMessage({ id: 'partner.country' })}
                           value={partner?.countryOfResidence}
                        />
                        {partnerStatus === 'pending' ? (
                           <div className="absolute inset-0 bg-neutral-100 opacity-25 flex items-center justify-center">
                              <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                           </div>
                        ) : null}
                     </div>
                  </div>
               </Card>
               {partner?.loyaltyPlans && partner?.loyaltyPlans.length > 0 && (
                  <Card>
                     <Card.Title>{intl.formatMessage({ id: 'partner.membership.information' })}</Card.Title>
                     {partner?.loyaltyPlans.map((l, index) => (
                        <React.Fragment key={index}>
                           <h3 className="px-4 pt-4 font-semibold">{l.loyaltyPlanName}</h3>
                           <div
                              className={`px-4 pb-6 relative ${
                                 index < partner.loyaltyPlans.length - 1 ? 'border-b' : ''
                              }`}
                           >
                              <div className="grid grid-cols-2 gap-4">
                                 <CardItem
                                    label={intl.formatMessage({ id: 'partner.points' })}
                                    value={l.points.toString()}
                                 />
                                 <CardItem
                                    label={intl.formatMessage({ id: 'partner.registrationChannel' })}
                                    value={l?.registrationChannel}
                                 />
                                 <CardItem
                                    label={intl.formatMessage({ id: 'partner.registrationDate' })}
                                    value={DateTime.fromISO(l.registrationDate).toFormat('yyyy-MM-dd')}
                                 />
                                 <CardItem label={intl.formatMessage({ id: 'partner.level' })} value={l.level} />
                              </div>
                           </div>
                        </React.Fragment>
                     ))}
                  </Card>
               )}
               <Card>
                  <Card.Title>{intl.formatMessage({ id: 'partner.title.additional' })}</Card.Title>
                  <div className="px-4 pb-6 relative">
                     <div className="grid grid-cols-2 gap-4">
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.state' })}
                           {...(partner &&
                              partner?.state && {
                                 value: partner.state,
                              })}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.swimming' })}
                           {...(partner &&
                              partner?.swimmingSkill && {
                                 value: partner.swimmingSkill ? 'Sí' : 'No',
                              })}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.phonenumber' })}
                           {...(partner &&
                              partner?.phoneNumber && {
                                 value: partner.phoneNumber,
                              })}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.typeBlood' })}
                           {...(partner &&
                              partner?.bloodType && {
                                 value: partner.bloodType,
                              })}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.allergies' })}
                           {...(partner &&
                              partner?.allergies && {
                                 value: partner.allergies,
                              })}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.passion' })}
                           {...(partner &&
                              partner?.passion && {
                                 value: partner.passion,
                              })}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.form.sourceId' })}
                           {...(partner &&
                              partner?.operaProfileSourceId && {
                                 value: partner.operaProfileSourceId,
                              })}
                        />
                     </div>
                  </div>
               </Card>
            </div>
            <div className="basis-4/12">
               <History idPlan={partner?.externalId} />
            </div>
         </div>
         <Modal visible={isOpen} onClose={() => setIsOpen(false)} size="md">
            <Modal.Header>
               <h2 className="px-4">{intl.formatMessage({ id: 'partner.confirmation.send' })}</h2>
            </Modal.Header>
            <Modal.Body>
               {partner && (
                  <div className="px-4">
                     <div className="flex items-center gap-x-2">
                        <FontAwesomeIcon icon={faEnvelope} className="text-neutral-500 text-xl" />
                        <div className="leading-tight">
                           <div className="text-neutral-400">{intl.formatMessage({ id: 'partner.email' })}</div>
                           <div>{partner.email}</div>
                        </div>
                     </div>
                  </div>
               )}
               <hr className="my-4" />
               <div className="p-4">{intl.formatMessage({ id: 'partner.confirmation.information' })}</div>
            </Modal.Body>
            <Modal.Footer>
               <div className="flex justify-end px-4 gap-4">
                  <Button bsStyle="success" onClick={sendConfimationEvent}>
                     {intl.formatMessage({ id: 'partner.confirmation.confirm' })}
                  </Button>
                  <Button onClick={() => setIsOpen(false)}>
                     {intl.formatMessage({ id: 'partner.confirmation.cancel' })}
                  </Button>
               </div>
            </Modal.Footer>
         </Modal>
      </>
   )
}

export default Partner
