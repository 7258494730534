import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { setUpInterceptor } from './utils/interceptor'
import { IntlProvider } from 'react-intl'
import App from './App'
import resources from './resources'
import { store } from './store'
import { Provider } from 'react-redux'

type LocaleType = 'es-mx' | 'en-us'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const searchParams = new URLSearchParams(document.location.search)

const getLocale = () => {
   const language = searchParams.get('locale')
   switch (language) {
      case 'es-mx':
         return 'es-mx' as LocaleType
      case 'en-us':
         return 'en-us' as LocaleType
   }
   return 'es-mx' as LocaleType
}

const allResources = {
   'es-mx': {
      ...resources['es-mx'],
   },
   'en-us': {
      ...resources['en-us'],
   },
}

const queryClient = new QueryClient()
const supLocale = getLocale()
const messages = allResources[supLocale]
setUpInterceptor(store)
root.render(
   <React.StrictMode>
      <QueryClientProvider client={queryClient}>
         <Provider store={store}>
            <IntlProvider locale={supLocale} messages={messages}>
               <BrowserRouter>
                  <App locale={supLocale} />
               </BrowserRouter>
            </IntlProvider>
         </Provider>
         <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
   </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
