import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getOperationList, GetOperationListRequest } from '../../api/operation'

function useGetOperationList(request: GetOperationListRequest) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_OPERATION_LIST, request ?? ''],
      queryFn: () => {
         if (request && !request.size) {
            return Promise.reject(new Error('Failed to fetch operations'))
         }
         return getOperationList(request)
      },
      enabled: !!request,
   })
}
export { useGetOperationList }
export default useGetOperationList
