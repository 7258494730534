import { useEffect, useState } from 'react'
import Title from '../../common/Title'
import { useIntl } from 'react-intl'
import Card from '../../common/Card'
import { useMutation } from '@tanstack/react-query'
import { importFileOperations, importFilePMSOperations } from '../../../api/operation'
import { useGetProgramFilter } from '../../../hooks/useGetProgramFilter'
import useIsProcessingImport from '../../../hooks/useIsProcessingImport/useIsProcessingImport'
import { useAppSelector } from '../../../store/hooks'
import Alert from 'ui/Alert'
import { Select } from 'ui/fields'
import FileUploadButton from '../../common/FileUploadButton'
import { createDateFromISO } from 'utils/dates'
import { getAlertType } from '../../../utils/partnerUtils'
import useFileUpload from '../../../hooks/useFIleUpload/useFileUpload'
import { toast } from 'react-toastify'

function ImportPMS() {
   const [error, setError] = useState(null)
   const { data: programs, isLoading } = useGetProgramFilter()
   const { data: dataImported } = useIsProcessingImport({ type: 'OPERATIONS_PMS' })
   const { locale } = useAppSelector((state) => state.base)
   const intl = useIntl()
   const { mutate: submitForm } = useMutation({
      mutationFn: importFilePMSOperations,
   })
   const onSucces = () => {
      toast.success(intl.formatMessage({ id: 'operations.import.success' }))
   }
   const onError = (e: any) => {
      toast.error(intl.formatMessage({ id: 'operations.import.error' }))
   }
   const { showStatusBanner, programId, setProgramId, file, setFile, handleFileChange } = useFileUpload(
      submitForm,
      0,
      onSucces,
      onError,
   )
   useEffect(() => {
      setError(null)
      setFile(null)
   }, [programId, setFile])

   const isLoadingImport =
      dataImported?.result?.status === 'PENDING' || showStatusBanner || dataImported?.result?.status === 'IN_PROGRESS'
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'operations.import.PMS' })}
         ></Title>
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="p-5">
               {dataImported && dataImported.result && (
                  <Alert className={'m-5'} type={getAlertType(dataImported.result.status)}>
                     <Alert.Body className="font-semibold">
                        <div>{dataImported?.message}</div>
                        <div>
                           {intl.formatMessage(
                              { id: 'partner.createAt' },
                              {
                                 date: createDateFromISO(dataImported.result.createdAt)
                                    .setLocale(locale)
                                    .toFormat("cccc d 'de' LLLL 'del' yyyy"),
                              },
                           )}
                        </div>
                     </Alert.Body>
                  </Alert>
               )}

               <Select
                  label={intl.formatMessage({ id: 'program.planName' })}
                  value={programId}
                  onChange={setProgramId}
                  showErrorText={true}
                  className="mb-4"
                  disabled={isLoadingImport || isLoading}
               >
                  <Select.Option value="">--</Select.Option>
                  {programs?.items.map((program) => (
                     <Select.Option key={program.id} value={program.id}>
                        {program.name}
                     </Select.Option>
                  ))}
               </Select>
               {!!programId && (
                  <FileUploadButton
                     handleFileChange={handleFileChange}
                     loading={isLoadingImport}
                     file={file}
                     setFile={setFile}
                     error={error}
                     setError={setError}
                     helpText={intl.formatMessage({ id: 'operations.importHelpText' })}
                     fileTypeList={['text/xlsx']}
                  />
               )}
            </Card>
         </div>
      </>
   )
}

export default ImportPMS
