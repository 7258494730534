import Login from '../user/Login'
import { verifyAuthThunk } from '../../features/authSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useEffect } from 'react'

type Props = {
   element: JSX.Element
}

function PrivateRoute({ element }: Props) {
   const dispatch = useAppDispatch()
   const { isAuthenticated, isPristine } = useAppSelector((state) => state.authToken)
   useEffect(() => {
      dispatch(verifyAuthThunk())
   }, [dispatch, isAuthenticated])

   if (isPristine) return null
   return isAuthenticated ? element : <Login />
}

export default PrivateRoute
