import classNames from 'classnames'
import React from 'react'

type Props = {
   value?: string
   className?: string
   children?: React.ReactNode
}

const Title = ({ value, className, children }: Props) => {
   return (
      <div className={classNames('bg-white border-b -mt-16 pt-16 mb-4', className)}>
         {value && <h1 className="text-2xl p-4">{value}</h1>}
         {children}
      </div>
   )
}

export default Title
