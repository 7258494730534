import React from 'react'
import { useGetOperationList } from '../../../hooks/useGetOperationList'
import { Button, Table } from 'ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
type Props = {
   currentValues: any
   onPageChange: (p: any) => any
   onSortChange: (p: any) => any
   onExport: () => any
   columns: any
   defaultProgram?: number
}
export default function TableOperations(props: Props) {
   const { currentValues, onPageChange, columns, defaultProgram, onSortChange, onExport } = props
   const intl = useIntl()
   const navigate = useNavigate()
   const { loyaltyPlanIds, ...rest } = currentValues
   const { data: operationList, status } = useGetOperationList({
      ...(loyaltyPlanIds.length ? { loyaltyPlanIds } : { loyaltyPlanIds: defaultProgram }),
      ...rest,
   })
   const onImportPms = () => {
      navigate('importPms')
   }
   const onImport = () => {
      navigate('import')
   }
   return (
      <>
         <Table
            fetching={status === 'pending'}
            columns={columns}
            data={operationList?.items ?? []}
            tableClassName="border-y"
            footerClassName="px-4"
            showTableResume
            pagination={{
               defaultPageSize: currentValues.size,
               defaultPageIndex: currentValues.page,
               totalSize: operationList?.totalSize ?? 0,
               onPageChange: onPageChange,
            }}
            sorting={{
               sorting:
                  currentValues.sort && currentValues.sort.length
                     ? [{ id: currentValues.sort[0], desc: currentValues.sort[1] === 'desc' }]
                     : null,
               onSortChange: onSortChange,
            }}
         >
            <div className="flex justify-between gap-x-2">
               <Button onClick={onImportPms} className={'flex gap-x-2 items-center text-[12px]'}>
                  <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'operations.import.PMS' })}
               </Button>

               <Button onClick={onImport} className={'flex gap-x-2 items-center text-[12px]'}>
                  <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'operations.import' })}
               </Button>
               <Button onClick={onExport} className={'flex gap-x-2 items-center text-[12px]'}>
                  <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                  {intl.formatMessage({ id: 'operations.export' })}
               </Button>
            </div>
         </Table>
      </>
   )
}
