import { useForm, useFieldArray } from 'react-hook-form'
// import { useIntl } from 'react-intl'
import { Check, Input } from 'ui/fields'
import HotelField from './HotelField'
import { contract, discount, getContracts, getDiscounts } from '../../../utils/programsUtils'
import { LoyaltyPlan } from '../../partners'
import _ from 'lodash'
import ContractsField from './ContractsField'
import { FormProvider } from './FormContext' // import the provider
import HotelDiscountForm from './HotelDiscountForm'

export type ProgramForm = LoyaltyPlan & {
   discounts: Array<discount>
   contracts: Array<contract>
}
type props = {
   program: LoyaltyPlan
}

export default function DetailProgramForm(props: props) {
   const { program } = props

   const { handleSubmit, register, setValue, getValues, watch, control, formState, trigger, setError, clearErrors } =
      useForm<ProgramForm>({
         defaultValues: {
            ...program,
         },
      })
   const { fields } = useFieldArray({
      name: 'contracts',
      control,
   })
   const hasDiscount = watch('hasDiscount')
   const onSubmit = (data: any) => {}
   const onChangeHotelField = (values: Array<number>) => {
      const formDiscountValue = getValues('discounts')
      const formContractsValues = getValues('contracts')
      const formBenefitsValues = getValues('benefits')
      const discounts = getDiscounts(values, formDiscountValue)
      const contracts = getContracts(values, formContractsValues)
      setValue('discounts', discounts)
      setValue('contracts', contracts)
      formBenefitsValues.forEach((b, index) => {
         const selected = _.intersection(values, b.hotels)
         setValue(`benefits.${index}.hotels`, selected)
      })
      if (values.length > 0) {
         setValue('hasDiscount', false)
      }
   }
   return (
      <FormProvider value={{ setValue, getValues, control, formState, trigger, setError, clearErrors }}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-4">
               <Input label="Nombre del programa" {...register('name')} width="350px" className="mb-6" />
               <Check label={'Activo'} {...register('isActive')} />
               <HotelField onChangeCallback={onChangeHotelField} control={control} setValue={setValue} />
               <div className="ml-[-16px] mr-[-16px] mb-4 mt-5 border-t border-gray-300"></div>
               <label>Contratos</label>
               <p className="text-gray-400">Elige los contratos a los que se les aplicará este plan de lealtad</p>
               <ContractsField fields={fields} />
               <div className="ml-[-16px] mr-[-16px] mb-4 mt-5 border-t border-gray-300"></div>
               <div className="my-4">
                  <input
                     type="checkbox"
                     id="hasDiscount"
                     className="react-switch-checkbox"
                     {...register('hasDiscount')}
                  />
                  <label className="inline-block react-switch-label" htmlFor={'hasDiscount'}>
                     Aplicar descuento
                  </label>
               </div>

               {hasDiscount && <HotelDiscountForm hotels={getValues('hotels')} control={control} />}
            </div>
         </form>
      </FormProvider>
   )
}
