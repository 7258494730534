import { Controller } from 'react-hook-form'
import Multiselect from 'react-widgets/esm/Multiselect'
import { useFormContext } from './FormContext'
import { useGetContractList } from '../../../hooks/useGetContract'
type props = {
   item: number
   hotelUri: string
}
const ContractSelectField = (props: props) => {
   const { hotelUri, item } = props
   const {
      control,
      formState: { errors },
      trigger,
      setError,
      clearErrors,
   } = useFormContext()
   const { data: contractList, status } = useGetContractList(hotelUri)
   if (status === 'pending' && !contractList) {
      return <>'...loading'</>
   }
   return (
      <Controller
         name={`contracts[${item}].contracts`}
         control={control}
         defaultValue={[]}
         rules={{ required: 'This field is required' }}
         render={({ field }) => (
            <>
               {contractList && (
                  <div className="mb-2">
                     <Multiselect
                        {...field}
                        data={contractList?.items}
                        textField="name"
                        dataKey="id"
                        placeholder="Selecciona un contrato"
                        onChange={(value) => {
                           if (value.length === 0) {
                              setError(`contracts[${item}].contracts`, {
                                 type: 'required',
                                 message: 'This field is required',
                              })
                           } else {
                              clearErrors(`contracts[${item}].contracts`)
                           }
                           field.onChange(value)
                           trigger(`contracts[${item}].contracts`)
                        }}
                        onBlur={(e) => {
                           field.onBlur()
                        }}
                     />
                     {errors.contracts && errors.contracts instanceof Array && errors.contracts[item] && (
                        <span className="text-red-500">{(errors.contracts[item] as any).contracts.message}</span>
                     )}
                  </div>
               )}
            </>
         )}
      />
   )
}

export default ContractSelectField
