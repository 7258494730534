import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPartner } from '../api/partner'
import { Partner } from '../components/partners'

export type PartnerState = {
   isPristine: boolean
   isFetching: boolean
   data: Partner | null
}

const initialState: PartnerState = {
   isPristine: true,
   isFetching: false,
   data: null,
}

export const getPartnerThunk = createAsyncThunk('getPartner', async (id: string, { rejectWithValue }) => {
   try {
      return await getPartner(id)
   } catch {
      return rejectWithValue(null)
   }
})

export const partnerSlice = createSlice({
   name: 'partnerListSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getPartnerThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPartnerThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getPartnerThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = partnerSlice.actions

export default partnerSlice.reducer
