import { useQuery } from '@tanstack/react-query'
import { getProgram } from '../../api/program'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetProgram() {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_PROGRAM],
      queryFn: () => getProgram(1),
   })
}

export { useGetProgram }
export default useGetProgram
