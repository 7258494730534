import qs from 'qs'

export function getCurrentValues(
   search: string,
   defaultValues: { loyaltyProgramId: number; from: string; to: string },
) {
   const result = qs.parse(search, {
      ignoreQueryPrefix: true,
   }) as {
      loyaltyProgramId?: string
      from?: string
      to?: string
   }
   return {
      loyaltyProgramId: result.loyaltyProgramId ? parseInt(result.loyaltyProgramId) : defaultValues.loyaltyProgramId,
      from: result.from || defaultValues.from,
      to: result.to || defaultValues.to,
   }
}
