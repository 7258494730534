import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useGetOperation } from '../../../hooks/useGetOperation'
import useGetPrograms from '../../../hooks/useGetPrograms'
import Title from '../../common/Title'
import { useIntl } from 'react-intl'
import { FormOperation, FormOperationData } from './form/OperationForm'
import useGetPartner from '../../../hooks/useGetPartner'
import { useMutation } from '@tanstack/react-query'
import { editOperation } from '../../../api/operation'
import { toast } from 'react-toastify'
import { TextSpinner } from 'ui'
import useGetChannelList from '../../../hooks/useGetChannelList'

function EditOperation() {
   const intl = useIntl()
   const { id, accountCode } = useParams()
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   const [loading, setLoading] = useState(false)
   const partnerId = searchParams.get('partnerId')
   const { data, isLoading } = useGetOperation({ id: id })
   const { data: partner, isLoading: loadingPartner } = useGetPartner(partnerId || '')
   const { data: plans, isLoading: isLoadingPlans } = useGetPrograms()
   const { data: channels, isLoading: loadingChannel } = useGetChannelList()

   const { mutate: formSubmit } = useMutation({
      mutationFn: editOperation,
   })
   const onSubmit = (data: FormOperationData) => {
      const { amount, roomsPerNight, numberOfNights, ...rest } = data
      setLoading(true)
      let request = {
         ...rest,
         memberId: partnerId,
         currency: 'MXN',
         account: accountCode,
         amount: Number(amount),
         roomsPerNight: Number(roomsPerNight),
         numberOfNights: Number(numberOfNights),
      } as any
      formSubmit(request as any, {
         onSuccess: (response) => {
            navigate(`/${accountCode}/operations`)
            toast.success(intl.formatMessage({ id: 'operations.update.success' }))
         },
         onError: (error: any) => {
            toast.error(
               error?.response?.data?.error
                  ? error.response.data.error
                  : intl.formatMessage({ id: 'operations.update.error' }),
            )
            setLoading(false)
         },
      })
   }

   const onCancel = () => {
      navigate(`/${accountCode}/operations`)
   }

   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'operations.edit' })}
         ></Title>
         {partner && data ? (
            <div className="flex justify-between m-4">
               <div>
                  <h2 className="text-3xl mb-1">{`${partner.firstName} ${partner.lastName}`}</h2>

                  <div className="text-neutral-600">{partner.email}</div>
                  <div className="text-neutral-500">{partner.id}</div>
               </div>
               <div className="text-right">
                  <h2 className="text-3xl mb-1">{partner.externalId}</h2>
                  <div className="text-neutral-400">ID de socio</div>
               </div>
            </div>
         ) : (
            <div className="m-4">
               <h2 className="text-3xl mb-4">
                  <TextSpinner
                     style={{
                        width: '50%',
                        color: 'transparent',
                        lineHeight: '1.0667em',
                     }}
                  >
                     .
                  </TextSpinner>
               </h2>

               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
               <TextSpinner
                  style={{
                     width: '35%',
                  }}
                  className="leading-none text-transparent mb-2"
               >
                  .
               </TextSpinner>
            </div>
         )}
         {isLoading || isLoadingPlans || loadingPartner || loadingChannel ? (
            <div className="flex justify-center items-center h-96">
               <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
         ) : (
            <FormOperation
               onSubmit={onSubmit}
               onCancel={onCancel}
               programs={plans}
               defautlValues={data ? { ...data, operationDate: data.createdAt } : undefined}
               isLoading={loading}
               channels={channels}
               disabled={true}
            />
         )}
      </>
   )
}

export default EditOperation
