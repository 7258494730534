import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPartnerList, GetPartnerListRequest } from '../api/partner'
import { Partner } from '../components/partners'

export type PartnerListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<Partner>
   totalSize: number
}

const initialState: PartnerListState = {
   isPristine: true,
   isFetching: false,
   items: [],
   totalSize: 0,
}
export const getPartnerListThunk = createAsyncThunk(
   'getPartnerList',
   async (request: GetPartnerListRequest, { rejectWithValue }) => {
      try {
         return await getPartnerList(request)
      } catch {
         return rejectWithValue(null)
      }
   },
)

export const partnerListSlice = createSlice({
   name: 'partnerListSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getPartnerListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPartnerListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload.items
         state.totalSize = payload.totalSize
      })
      builder.addCase(getPartnerListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = partnerListSlice.actions

export default partnerListSlice.reducer
