import { Country } from '../../../api/countries'
import { useWatch } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { getCountryPhoneCode, getPhonesOptions } from '../../../utils/partnerUtils'
import './phoneSelector.css'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

type Props = {
   countries: Array<Country>
   control: any
   register: any
   error?: any
   isTouched?: boolean
}
export type UserInfoFormValuesInternal = {
   name?: string
   lastName?: string
   email?: string
   code?: string
   telephone?: string
}
const PhoneCodeSelector = ({ countries, control, register, error, isTouched }: Props) => {
   const code = useWatch({
      control: control,
      name: 'countryCode',
   })
   const intl = useIntl()
   const getField = () => {
      if (!countries) {
         return null
      }
      const codes = getPhonesOptions(countries) as Array<any>
      const display = getCountryPhoneCode(countries, code)
      return (
         <span
            className={classNames('w-[85px] inline-block', {
               'has-error': error,
               'has-success': !error && isTouched,
            })}
         >
            <span className={'absolute left-[15px] top-[5px] text-left flex items-center'}>
               <div className="w-[75px] inline-block overflow-hidden whitespace-nowrap text-ellipsis">
                  {display || '--'}
               </div>
               <FontAwesomeIcon icon={faSort} />
            </span>
            <select
               id="countryCode"
               {...register('countryCode', { required: intl.formatMessage({ id: 'partner.errors.areaCode' }) })}
               className={'itm-phone-select form-control'}
            >
               {codes.map((countrie, index) => (
                  <option key={`${countrie.code}-${index}`} value={countrie.code} className="text-black">
                     {countrie.value}
                  </option>
               ))}
            </select>
         </span>
      )
   }
   return getField()
}

export default PhoneCodeSelector
