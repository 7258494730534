import React from 'react'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button, Dropdown } from 'ui'
import { Input } from 'ui/fields'
import { ProgramListState } from '../../../features/programListSlice'
import { useGetLevelList } from '../../../hooks/useGetLevelList'
import MultiCheck from 'ui/MultiCheck/MultiCheck'

export type PartnerFilterForm = {
   query: string
   loyaltyPlanIds: Array<number>
   channelIds: Array<number>
   levelIds: Array<number>
}
export type ProgramFilterForm = {
   query: string
   loyaltyPlanIds: Array<number>
   channelIds: Array<number>
   levelIds: Array<number>
}
type Props = {
   className?: string
   onSubmit: (data: ProgramFilterForm) => void
   programs?: ProgramListState
   defaultValues?: ProgramFilterForm
}

const Filters = ({ className, onSubmit, programs, defaultValues }: Props) => {
   const { data: levels, status: levelStatus } = useGetLevelList()
   const { handleSubmit, register, watch, control, resetField } = useForm<ProgramFilterForm>({
      defaultValues: {
         query: defaultValues?.query || '',
         loyaltyPlanIds: defaultValues?.loyaltyPlanIds || [],
         channelIds: defaultValues?.channelIds || [],
         levelIds: defaultValues?.levelIds || [],
      },
      mode: 'onTouched',
   })
   const formButton = React.useRef<HTMLFormElement>(null)
   const intl = useIntl()

   const onSubmitEvent = (data: ProgramFilterForm) => {
      const { query, ...rest } = data
      const result = { ...rest } as ProgramFilterForm
      if (query && query.trim() !== '') {
         result.query = query.trim()
      }
      onSubmit(result)
   }

   const drawCount = (sizeSelected: number, total: number, fetching: boolean, word = 'Todas') => {
      if (fetching) {
         return '...'
      }
      if (sizeSelected > 0) {
         return `${sizeSelected} de ${total}`
      }
      return word
   }

   return (
      <div className={classNames(className, 'mb-6')}>
         <form onSubmit={handleSubmit(onSubmitEvent)} ref={formButton}>
            <div className="flex justify-between items-center">
               <div className="flex gap-x-2">
                  {programs && (
                     <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                        <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                           {intl.formatMessage({ id: 'partners.program' })}:{' '}
                           {drawCount(
                              watch('loyaltyPlanIds')?.length,
                              programs.items.length,
                              programs.isFetching || programs.isPristine,
                              'Todos',
                           )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <ul>
                              <Controller
                                 control={control}
                                 name="loyaltyPlanIds"
                                 render={({ field: { ref, ...rest } }) => (
                                    <MultiCheck {...rest}>
                                       {programs.items.map((option) => {
                                          if (!option.hasPointBenefit) {
                                             return null
                                          }
                                          return (
                                             <li key={option.id} className="hover:bg-gray-100">
                                                <MultiCheck.Check value={option.id} label={option.name} />
                                             </li>
                                          )
                                       })}
                                    </MultiCheck>
                                 )}
                              />
                           </ul>
                        </Dropdown.Menu>
                     </Dropdown>
                  )}
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {intl.formatMessage({ id: 'partners.level' })}:{' '}
                        {drawCount(
                           watch('levelIds')?.length,
                           levels?.items?.length ?? 0,
                           levelStatus === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="levelIds"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {levels?.items?.map((option) => (
                                       <li key={option.id} className="hover:bg-gray-100">
                                          <MultiCheck.Check value={option.id} label={option.name} />
                                       </li>
                                    ))}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
               </div>

               <div className="flex items-center relative">
                  {watch('query') && (
                     <FontAwesomeIcon
                        icon={faX}
                        className="absolute right-14 cursor-pointer"
                        onClick={() => {
                           resetField('query', { defaultValue: '' })
                           formButton.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                        }}
                     />
                  )}
                  <Input
                     {...register('query')}
                     className="!rounded-r-none"
                     containerClassName=" !m-0"
                     placeholder="Nombre del plan"
                  />
                  <Button bsStyle="gray" type="submit" className="flex items-center !border-l-0 !rounded-l-none">
                     <i className="invisible inline-block max-w-0">.</i>
                     <FontAwesomeIcon icon={faSearch} />
                  </Button>
               </div>
            </div>
         </form>
      </div>
   )
}

export default Filters
