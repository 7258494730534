import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSummary, GetSummaryRequest } from '../api/summary'
import { Summary } from '../components/dashboard'

export type ProfileState = {
   isPristine: boolean
   isFetching: boolean
   data: Summary | null
}

const initialState: ProfileState = {
   isPristine: true,
   isFetching: false,
   data: null,
}

export const getSummaryThunk = createAsyncThunk(
   'getSummary',
   async (request: GetSummaryRequest, { rejectWithValue }) => {
      try {
         const summary = await getSummary(request)
         return summary
      } catch {
         return rejectWithValue(null)
      }
   },
)

export const summarySlice = createSlice({
   name: 'summary',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getSummaryThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getSummaryThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getSummaryThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = summarySlice.actions

export default summarySlice.reducer
