import React, { useRef } from 'react'
import { Button } from 'ui'

function FileUploadButton({
   loading,
   file,
   setFile,
   handleFileChange,
   error,
   setError,
   helpText,
   fileTypeList = ['text/csv'],
}: any) {
   const inputFile = useRef<HTMLInputElement | null>(null)
   const handleOnDragOver = (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault()
   }
   const validateFile = (file: File) => {
      if (file.size > 10 * 1024 * 1024) {
         setError('El archivo debe ser menor a 10MB')
         return false
      }
      console.log(file.type)

      if (fileTypeList.indexOf(file.type) < 0) {
         setError('El archivo no tiene el formato correcto. Selecciona otro archivo.')
         return false
      }
      setError(null)
      return true
   }
   const handleClickButton = () => {
      if (inputFile.current) {
         inputFile?.current.click()
      }
   }
   const handleOnDrop = (event: React.DragEvent<HTMLElement>) => {
      if (loading) return null
      event.preventDefault()
      const file = event.dataTransfer.files[0]
      const errorFile = validateFile(file)
      if (errorFile) {
         setFile(file)
         handleFileChange(file)
      }
   }
   const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
         const file = event.target.files[0]
         const errorFile = validateFile(file)
         if (errorFile) {
            setFile(file)
            handleFileChange(file)
         }
      }
   }
   return (
      <section
         onDragOver={handleOnDragOver}
         onDrop={handleOnDrop}
         className="p-10 border-2 border-dashed border-gray-300 hover:border-gray-400 text-center"
      >
         <div className={'text-center flex flex-col items-center'}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#ccc" width={45} viewBox="0 0 384 512">
               <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
            </svg>
            <p className=" max-w-[424px] mx-auto mt-4 mb-3">{helpText}</p>
            <Button bsStyle="primary" disabled={loading} onClick={handleClickButton}>
               Seleccionar archivo
            </Button>
            <p className="text-muted mt-4">
               Formatos soportados: {fileTypeList.map((f: string) => f.toUpperCase().split('/')[1]).join(', ')}. Peso
               máximo: 10MB
            </p>
            {error && <p className="text-red-500 mt-4">{error}</p>}
         </div>
         <input type="file" className="opacity-0" id="uploadRateFile" ref={inputFile} onChange={handleOnChange} />
      </section>
   )
}

export default FileUploadButton
