import { apiCall } from '../utils/api'
export type Country = {
   id: number
   name: string
   code: string
   order_?: number | null
   phoneCode?: string
   disabled?: boolean | null
}
export type RequestType = {
   locale: string
   account?: string
}

export async function getCountryList(request: RequestType, access_token?: string): Promise<Array<Country>> {
   const { locale, account } = request
   const result = await apiCall(
      {
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/countries`,
         method: 'GET',
      },
      account,
      locale,
      access_token,
   )
   return result.data || []
}
