import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getHotelList } from '../../api/hotels'

function useGetHotelList(params: any) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_HOTEL_LIST],
      queryFn: () => getHotelList(params),
      enabled: !!params,
   })
}

export { useGetHotelList }
export default useGetHotelList
