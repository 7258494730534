import { apiCall } from '../utils/api'
export type GetHotelListRequest = {
   page?: number
   pageSize?: number
   accountCode?: string
}
type Destination = {
   id: number
   name: string
   airportCode: string
}

type Amenity = {
   id: number
   code: string | null
   name: string
}

type View = {
   id: number
   code: string
   name: string
}

type Room = {
   id: number
   surface: number
   view: View
   beddingTypeSets: Array<any>
   maxCapacity: number
   maxCapacityAdults: number
   maxCapacityKids: number
   minCapacity: number
   checkInTime: number
   checkOutTime: number
   isActive: boolean
   showInWebSite: boolean | null
   order: number | null
   amenities: Amenity[]
   rateThresholds: Array<any>
   totalRooms: number
   name: string
   description: string | null
   copyAmenitiesFromRoomId: number | null
   replaceRateThresholds: Array<any> | null
}

export type Hotel = {
   id: number
   name: string
   code: string
   ranking: number | null
   rating: number
   chain: string | null
   place: string | null
   destination: Destination
   category: string | null
   rooms: Room[]
   isActive: boolean
   isExternal: boolean | null
   latitude: number
   longitude: number
   externalPlaceId: string | null
   dateCreated: string | null
   lastUpdated: string | null
   addressees: Array<any>
   content: string | null
   configuration: string | null
   description: string
}
export type hotelListState = {
   items: Array<Hotel>
   totalSize: number
}
export async function getHotelList(request?: GetHotelListRequest): Promise<hotelListState> {
   const response = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_CORE_API}/hotels`,
      method: 'GET',
      params: request,
   })
   return { items: response.data, totalSize: parseInt(response.headers['x-total-count']) }
}
