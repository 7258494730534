import classNames from 'classnames'
import React from 'react'

type Props = {
   className?: string
   children?: React.ReactNode
}

const Card = ({ children, className }: Props) => {
   return <div className={classNames('border bg-white rounded-sm mb-8', className)}>{children}</div>
}
const Title = ({ children, className }: Props) => {
   return <div className={classNames('border-b px-4 py-3 text-xl', className)}>{children}</div>
}

Card.Title = Title

export default Card
