import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { loadAccount } from '../../features/baseSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getProfileThunk } from '../../features/profileSlice'

type Props = {
   element: JSX.Element
}

function PrivateRoute({ element }: Props) {
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { accountCode } = useParams()
   const profile = useAppSelector((state) => state.profile)
   const { account: accountBase } = useAppSelector((state) => state.base)

   useEffect(() => {
      if (profile.isPristine) {
         dispatch(getProfileThunk())
      }
   }, [dispatch, profile])

   useEffect(() => {
      if (accountCode && !profile.isFetching && !profile.isPristine && profile.data) {
         const account = profile.data.accounts.find((account) => account.code === accountCode)
         if (account) {
            dispatch(loadAccount(account))
         } else {
            navigate(`/accounts`)
         }
      }
   }, [dispatch, navigate, profile, accountCode, accountBase])

   if (!accountBase) {
      return <></>
   }

   return accountBase ? element : <>Error</>
}

export default PrivateRoute
