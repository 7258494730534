import titles from './titles'
import partners from './partners'
import operations from './operations'
import dashboard from './dashboard'
import program from './program'

// eslint-disable-next-line
export default {
   'es-mx': Object.assign({}, titles, partners, operations, dashboard, program),
}
