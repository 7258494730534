import { useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Select } from 'ui/fields'
import useGetServiceTypes from '../../../../hooks/useGetServiceTypeList/useGetServiceTypeList'

function ServiceTypeField(props: {
   disabled?: boolean
   getLabel: Function
   control: any
   errors: any
   intl: any
   formatOnchange: Function
   dirtyFields: any
   setValue: Function
   isEdit?: boolean
}) {
   const { disabled, getLabel, control, errors, dirtyFields, formatOnchange, setValue, isEdit } = props
   const getLoyalPlanId = useWatch({ control, name: 'loyaltyPlanId' })
   const getOperationType = useWatch({ control, name: 'operationType' })
   const { data: serviceTypes, isLoading: loadingServiceTypes } = useGetServiceTypes({
      ...(getLoyalPlanId && { loyaltyPlanId: getLoyalPlanId }),
      ...(getOperationType && { operationType: getOperationType }),
   })
   const intl = useIntl()
   useEffect(() => {
      if ((getLoyalPlanId || getOperationType) && !isEdit) {
         setValue('serviceType', '', { shouldValidate: true })
      }
   }, [getLoyalPlanId, getOperationType, isEdit, setValue])

   if (loadingServiceTypes) {
      return (
         <Select
            label={getLabel(intl.formatMessage({ id: 'operation.form.serviceId' }))}
            value={''}
            onChange={(i: string) => {}}
            disabled={true}
         >
            <Select.Option value="">--</Select.Option>
         </Select>
      )
   }

   return (
      <>
         <Controller
            name={`serviceType`}
            control={control}
            rules={{ required: intl.formatMessage({ id: 'operation.form.errors.serviceId' }) }}
            render={({ field: { value, onChange } }) => (
               <Select
                  label={getLabel(intl.formatMessage({ id: 'operation.form.serviceId' }))}
                  value={value}
                  onChange={(i: string) => onChange(formatOnchange(i))}
                  error={errors?.serviceType?.message}
                  showErrorText={true}
                  className="mb-4"
                  isTouched={dirtyFields.serviceType}
                  disabled={disabled || loadingServiceTypes}
               >
                  <Select.Option value="">--</Select.Option>
                  {serviceTypes?.map((type: any) => (
                     <Select.Option key={type.id} value={type.id}>
                        {type.description}
                     </Select.Option>
                  ))}
               </Select>
            )}
         />
      </>
   )
}

export default ServiceTypeField
