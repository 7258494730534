import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../features/authSlice'
import baseSlice from '../features/baseSlice'
import profileSlice from '../features/profileSlice'
import partnerListSlice from '../features/partnerListSlice'
import programListSlice from '../features/programListSlice'
import channelListSlice from '../features/channelListSlice'
import levelListSlice from '../features/levelListSlice'
import operationListSlice from '../features/operationListSlice'
import summarySlice from '../features/summarySlice'
import partnerSlice from '../features/partnerSlice'
import historySlice from '../features/historySlice'
import programSlice from '../features/programSlice'
import hotelListSlice from '../features/hotelListSlice'
import contractListSlice from '../features/contractListSlice'
import pointSlice from '../features/pointSlice'

export const store = configureStore({
   reducer: {
      authToken: authSlice,
      base: baseSlice,
      profile: profileSlice,
      partnerList: partnerListSlice,
      programList: programListSlice,
      channelList: channelListSlice,
      levelList: levelListSlice,
      operationList: operationListSlice,
      summary: summarySlice,
      partner: partnerSlice,
      history: historySlice,
      program: programSlice,
      hotelList: hotelListSlice,
      contractList: contractListSlice,
      points: pointSlice,
   },
})
export type StoreType = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
