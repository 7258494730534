import { useFieldArray, Control, Controller } from 'react-hook-form'
import { ProgramForm } from './ProgramForm'
import { Input } from 'ui/fields'
import { useFormContext } from './FormContext'
import { useGetHotelList } from '../../../hooks/useGetHotelList'

type Props = {
   hotels: Array<any>
   control: Control<ProgramForm, any>
}
const HotelDiscountForm = (props: Props) => {
   const { control } = props
   const {
      formState: { errors },
      setError,
      clearErrors,
      trigger,
   } = useFormContext()
   const { fields } = useFieldArray({
      name: 'discounts',
      control,
   })
   const { data, isLoading } = useGetHotelList({ page: 1, pageSize: 1000 })

   if (isLoading && !data) {
      return <>'...loading'</>
   }

   const drawItems = () => {
      return fields.map((item, index) => {
         const content = data?.items?.find((hotel) => hotel.id === item.hotelId)
         return (
            <tr key={item.id} className="border-b last:border-b-0">
               <td className="flex">{content && content.name}</td>
               <td>
                  <Controller
                     name={`discounts.${index}.discount`}
                     control={control}
                     defaultValue={0}
                     rules={{ required: 'This field is required' }}
                     render={({ field }) => (
                        <div className="">
                           <Input
                              inputGroup={'%'}
                              inputGroupAling={'right'}
                              type="number"
                              placeholder="Descuento"
                              {...field}
                              containerClassName={'text-right mb-2 ml-2'}
                              className={'w-24'}
                              onChange={(value) => {
                                 if (!value) {
                                    setError(`discounts.${index}.discount`, {
                                       type: 'required',
                                       message: 'This field is required',
                                    })
                                 } else {
                                    clearErrors(`discounts.${index}.discount`)
                                 }
                                 field.onChange(value)
                                 trigger(`discounts.${index}.discount`)
                              }}
                              onBlur={(e) => {
                                 field.onBlur()
                              }}
                           />
                           {errors.discounts && errors.discounts instanceof Array && errors.discounts[index] && (
                              <span className="text-red-500">{(errors.discounts[index] as any).discount.message}</span>
                           )}
                        </div>
                     )}
                  />
               </td>
            </tr>
         )
      })
   }
   return (
      <table className="table-auto w-full">
         <thead>
            <tr className="border-b ">
               <th className="text-left">Hotel</th>
               <th style={{ width: '180px' }}>Porcentaje de descuento</th>
            </tr>
         </thead>
         <tbody>{drawItems()}</tbody>
      </table>
   )
}

export default HotelDiscountForm
