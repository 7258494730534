import qs from 'qs'
import { LoyaltyPlan } from '../components/partners'
import { ProgramForm } from '../components/programs/components/ProgramForm'
type Request = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: number[]
   channelIds: number[]
   levelIds: number[]
   from?: string
   to?: string
}
export type discount = {
   discount: number
   hotelId: number
   hotelName: string
   id: number
}
export type contract = {
   contracts: Array<number | null>
   hotelId: number
   hotelName: string
   id: number
}
export function getCurrentValues(search: string): Request {
   const result = qs.parse(search, {
      comma: false,
      ignoreQueryPrefix: true,
   }) as {
      query?: string
      page?: string
      size?: string
      sort?: string[]
      loyaltyPlanIds?: string
      channelIds?: string
      levelIds?: string
   }
   return {
      query: result.query || '',
      page: parseInt(result.page || '0'),
      size: parseInt(result.size || '20'),
      sort: result.sort || ['createdDate', 'desc'],
      loyaltyPlanIds: result.loyaltyPlanIds ? result.loyaltyPlanIds.split(',').map(Number) : [],
      channelIds: result.channelIds ? result.channelIds.split(',').map(Number) : [],
      levelIds: result.levelIds ? result.levelIds.split(',').map(Number) : [],
   } as Request
}
export function getDiscounts(hotels: Array<number>, discounts: Array<discount>) {
   const result: Array<discount> = []

   hotels?.forEach((hotel: number) => {
      const discount = discounts.find((d) => d.hotelId === hotel)
      if (discount) {
         result.push(discount)
      } else {
         result.push({ discount: 0, hotelId: hotel, hotelName: '', id: 0 })
      }
   })
   return result
}
export function getContracts(hotels: Array<number>, contracts: Array<contract>) {
   const result: Array<contract> = []
   hotels?.forEach((hotel: number) => {
      const contract = contracts.find((c) => c.hotelId === hotel)
      if (contract) {
         result.push(contract)
      } else {
         result.push({ contracts: [], hotelId: hotel, hotelName: '', id: 0 })
      }
   })
   return result
}
export function getInitialValues(data: LoyaltyPlan): ProgramForm {
   const initialValues: Partial<ProgramForm> = {
      benefits: [],
      discounts: [],
      contracts: [],
      hotels: [],
   }
   if (data) {
      const { hasDiscount, hotels, photos, ...rest } = data

      const result: ProgramForm = Object.assign(initialValues, {
         hotels: hotels.map((hotel) => hotel.hotelId),
         hasDiscount: hasDiscount,
         discounts: hotels.map(({ contracts, ...rest }) => ({ ...rest })),
         contracts: hotels.map(({ discount, ...rest }) => ({ ...rest })),
         ...rest,
      }) as ProgramForm
      return result
   } else {
      return initialValues as ProgramForm
   }
}
