import { axiosPrivate } from './api'
import { isValidAccessToken } from './auth'
import { StoreType } from '../store'
import { clear } from '../features/authSlice'

export const setUpInterceptor = (store: StoreType) => {
   axiosPrivate.interceptors.request.use(
      async (config) => {
         const { authToken, base } = store.getState() || {}
         const access_token = authToken?.token
         if (base.account && config?.headers) {
            config.headers['account'] = base.account.code
         }
         if (base.locale && config?.headers) {
            config.headers['Accept-Language'] = base.locale
            if (config.method === 'get') {
               config.params = { locale: base.locale, ...config.params }
            } else {
               config.data = { locale: base.locale, ...config.data }
            }
         }
         if (!isValidAccessToken(access_token)) {
            store.dispatch(clear())
         } else {
            if (config?.headers) {
               config.headers['Authorization'] = `Bearer ${access_token}`
            }
         }

         return config
      },
      (error) => {
         return Promise.reject(error)
      },
   )
}
