import Multiselect from 'react-widgets/Multiselect'
import { ProgramForm } from './ProgramForm'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { useFormContext } from './FormContext'
import { Hotel } from '../../../api/hotels'
import { useGetHotelList } from '../../../hooks/useGetHotelList'
type HotelFieldProps = {
   control?: Control<ProgramForm, any>
   onChangeCallback: (values: Array<any>) => void
   setValue: UseFormSetValue<ProgramForm>
}

function HotelField(props: HotelFieldProps) {
   const {
      control,
      formState: { errors },
      trigger,
      setError,
      clearErrors,
   } = useFormContext()
   const { data: hotelList, isLoading, isPending } = useGetHotelList({ page: 1, pageSize: 1000 })
   const onChange = (values: Array<Hotel>) => {
      const selectedValues: Array<number> = []

      values.forEach((value: Hotel) => {
         selectedValues.push(value.id)
      })
      if (values.length === 0) {
         setError('hotels', { type: 'required', message: 'This field is required' })
      } else {
         clearErrors('hotels')
      }

      trigger('hotels')
      if (props.onChangeCallback && typeof props.onChangeCallback === 'function') {
         props.onChangeCallback(selectedValues)
      }
   }

   return (
      <div className="mt-6">
         <label htmlFor="hotels"> Hoteles</label>
         {isPending || isLoading ? (
            '...loading'
         ) : (
            <Controller
               name="hotels"
               control={control}
               defaultValue={[]}
               rules={{ required: 'This field is required' }}
               render={({ field }) => (
                  <Multiselect
                     {...field}
                     data={hotelList?.items}
                     textField="name"
                     dataKey="id"
                     onChange={(values) => {
                        field.onChange(values)
                        onChange(values)
                     }}
                     onBlur={(e) => {
                        field.onBlur()
                     }}
                  />
               )}
            />
         )}
         <span className="text-gray-400 block">Elige los hoteles a los que se les aplicará este plan de lealtad</span>
         {errors && errors.hotels && typeof errors.hotels.message === 'string' && (
            <span className="text-red-500">{errors?.hotels?.message}</span>
         )}
      </div>
   )
}

export default HotelField
