import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './dateRangeSelector.css'
import { createDate } from 'utils/dates'

type Props = {
   from?: string
   to?: string
   placeholder?: string
   reload?: boolean
   monthsShown?: number
   onChange: (from: string, to: string) => void
   onClear?: () => void
}

const DateRangeSelector = ({ from, to, placeholder, monthsShown, reload, onChange, onClear }: Props) => {
   const [dateRange, setDateRange] = useState<[Date | null | undefined, Date | null | undefined]>([null, null])

   useEffect(() => {
      setDateRange([createDate(from)?.toJSDate(), createDate(to)?.toJSDate()])
   }, [from, to, reload])

   const [startDate, endDate] = dateRange
   function onChangeEvent(update: [Date | null | undefined, Date | null | undefined]) {
      setDateRange(update)
      if (update[0] === null && update[1] === null) {
         onClear && onClear()
      } else {
         const from = update[0] && createDate(update[0])
         const to = update[1] && createDate(update[1])
         onChange && from && to && onChange(from.toFormat('yyyy-MM-dd'), to.toFormat('yyyy-MM-dd'))
      }
   }
   return (
      <DatePicker
         className="border border-gray-300 rounded-md p-2 w-[240px] text-[#495057]"
         calendarIconClassName="fill-[#495057]"
         {...(startDate && { startDate })}
         {...(endDate && { endDate })}
         onChange={onChangeEvent}
         isClearable
         showIcon
         selectsRange
         monthsShown={monthsShown || 2}
         showPreviousMonths={false}
         placeholderText={placeholder}
         popperPlacement="bottom-start"
         dateFormat={'dd LLL, yyyy'}
      />
   )
}

export default DateRangeSelector
