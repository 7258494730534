import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoyaltyPlan } from '../components/partners'
import { getProgram } from '../api/program'

export type ProgramState = {
   isPristine: boolean
   isFetching: boolean
   data: LoyaltyPlan | null
}

const initialState: ProgramState = {
   isPristine: true,
   isFetching: false,
   data: null,
}

export const getProgramThunk = createAsyncThunk('getProgram', async (id: number, { rejectWithValue }) => {
   try {
      return await getProgram(id)
   } catch {
      return rejectWithValue(null)
   }
})

export const programSlice = createSlice({
   name: 'programSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getProgramThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getProgramThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getProgramThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = programSlice.actions

export default programSlice.reducer
