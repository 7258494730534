import { useQuery } from '@tanstack/react-query'

import { getHistory, GetHistoryRequest } from '../../api/history'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetPartnerHistory(params: Partial<GetHistoryRequest>) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_PARTNER, params],
      queryFn: () => getHistory(params as GetHistoryRequest),
      enabled: !!params.memberId,
   })
}

export { useGetPartnerHistory }
export default useGetPartnerHistory
