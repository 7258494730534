import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getOperation } from '../../api/operation'

function useGetOperation(request: { id?: string }) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_OPERATION, request ?? ''],
      queryFn: () => {
         return getOperation(request)
      },
      enabled: !!request,
   })
}
export { useGetOperation }
export default useGetOperation
