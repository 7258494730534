// eslint-disable-next-line
export default {
   'operations.reservation': 'Reservación',
   'operations.date': 'Fecha',
   'operations.creationDate': 'Fecha de creacion',
   'operations.reservation-date': 'Fecha de reservacion',
   'operations.partner': 'Socio',
   'operations.accumulatedPoints': 'Puntos acumulados',
   'operations.redeemedPoints': 'Puntos redimidos',
   'operations.dates': 'Fechas',
   'operations.other': 'Otra',
   'operations.any': 'Cualquiera',
   'operations.selectDate': 'Seleccionar fecha',
   'operations.export': 'Exportar',
   'operations.import': 'Importar operaciones',
   'operations.importHelpText': 'Arrastra o ingresa el archivo con las operaciones a importar.',

   'operations.create': 'Crear Operación',
   'operation.form.memberId': 'Socio',
   'operation.errors.memberId': 'El socio es requerido',
   'operation.errors.loyaltyPlanId': 'El plan de lealtad es requerido',
   'operation.form.loyaltyPlanId': 'Plan de lealtad',
   'operation.form.levelId': 'Nivel',
   'operation.errors.levelId': 'El nivel es requerido',
   'operation.errors.operationType': 'El tipo de operación es requerido',
   'operation.form.operationType': 'Tipo de operación',
   'operation.errors.serviceType': 'El tipo de servicio es requerido',
   'operation.form.serviceType': 'Tipo de servicio',
   'operation.form.reservationUuid': 'UUID de la reservación',
   'operation.form.ReservationName': 'Nombre de la reservación',
   'operation.errors.ReservationName': 'El nombre de la reservación es requerido',
   'operation.form.reservationCode': 'Código de la reservación',
   'operation.errors.currency': 'La moneda es requerida',
   'operation.form.currency': 'Moneda',
   'operation.form.reservationDate': 'Fecha de reservación',
   'operation.errors.reservationDate': 'La fecha de reservación es requerida',
   'operation.errors.reservationSubTotal': 'El subtotal de la reservación es requerido',
   'operation.form.reservationSubTotal': 'Subtotal',
   'operation.form.reservationTotal': 'Total',
   'operation.errors.reservationTotal': 'El total de la reservación es requerido',
   'operation.form.operationType.REDEMPTION': 'Redención',
   'operation.form.operationType.ACCUMULATION': 'Acumulación',
   'operation.form.currency.mxn': 'MXN',
   'operation.form.currency.usd': 'USD',
   'operations.edit': 'Editar operacion',
   'operations.table.plan': 'Plan de lealtad/Nivel',
   'operations.table.status': 'Estatus',
   'operations.import.PMS': 'Importar operaciones pms',
   'operations.table.partner.id': 'ID Socio',
   'operations.table.partner.number': 'Número de socio',
   'operations.table.generic.type': 'Tipo genérico de operación',
   'operations.table.type': 'Tipo de operación',
   'operations.table.date.stay': 'Fecha de la estancia',
   'operations.table.date.application': 'Fecha de aplicación',
   'operations.table.registrationChannel': 'Canal de registro',
   'operations.table.accumulatedPoints': 'Puntos',
   'operations.table.CN': 'Cuartos noche',
   'operation.form.errors.serviceId': 'El servicio es requerido',
   'operation.form.serviceId': 'Tipo de servicio',
   'operation.form.amount': 'Monto',
   'operation.errors.amount': 'El monto es requerido',
   'operation.form.numberOfNights': 'Número de noches',
   'operation.errors.numberOfNights': 'El número de noches es requerido',
   'operation.form.rooms': 'Habitaciones',
   'operation.errors.rooms': 'El número de habitaciones es requerido',
   'operation.form.invoice': 'Factura',
   'operation.errors.invoice': 'La factura es requerida',
   'operation.form.reference': 'Referencia',
   'operation.errors.reference.caracters': 'La referencia solo permite 800 caracteres',
   'operation.form.stayDate': 'Fecha de estancia',
   'operation.errors.stayDate': 'La fecha de estancia es requerida',
   'operation.form.operationDate': 'Fecha de operación',
   'operation.errors.operationDate': 'La fecha de operación es requerida',
   'operations.table.type.REDEMPTION': 'Redención',
   'operations.table.type.ACCUMULATION': 'Acumulación',
   'operations.table.type.APPLIED': 'Aplicada',
   'operations.table.type.PENDING': 'Pendiente',
   'operations.table.type.CANCELLED': 'Cancelada',
   'operations.create.success': 'Operación creada con éxito',
   'operations.create.error': 'Error al crear la operación',
   'operations.update.success': 'Operación actualizada con éxito',
   'operations.update.error': 'Error al actualizar la operación',
   'operations.form.registationChannel': 'Canal de registro',
   'operations.form.error.registationChannel': 'El canal de registro es requerido',
   'operations.import.success': 'Operaciones subida para procesar',
   'operations.import.error': 'Error al importar las operaciones',
}
