import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getProfile } from '../api/profile'
import { Profile } from '../components/user/Login'

export type ProfileState = {
   isPristine: boolean
   isFetching: boolean
   data: Profile | null
}

const initialState: ProfileState = {
   isPristine: true,
   isFetching: false,
   data: null,
}

export const getProfileThunk = createAsyncThunk('getProfile', async (_, { rejectWithValue }) => {
   try {
      const profile = await getProfile()
      if (profile) {
         return { ...profile }
      }
      return null
   } catch {
      return rejectWithValue(null)
   }
})

export const profileSlice = createSlice({
   name: 'profile',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getProfileThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getProfileThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getProfileThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = profileSlice.actions

export default profileSlice.reducer
