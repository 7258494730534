import { apiCall } from '../utils/api'
export type requestChannelList = {
   uri: string
}

export async function getContractList(request: requestChannelList): Promise<{ items: Array<any> }> {
   const { uri } = request
   try {
      const response = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/hotels/${uri}/contracts`,
         method: 'GET',
      })
      if (response.status !== 200) {
         throw new Error(`error ${response.status}`)
      }
      return { items: response.data }
   } catch {
      return { items: [] }
   }
}
