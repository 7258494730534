import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPoints } from '../api/points'

export type PointsState = {
   isPristine: boolean
   isFetching: boolean
   data: { memberId: number; points: number } | null
}

const initialState: PointsState = {
   isPristine: true,
   isFetching: false,
   data: null,
}

export const getPointsThunk = createAsyncThunk('getPoints', async (id: string, { rejectWithValue }) => {
   try {
      return await getPoints(id)
   } catch {
      return rejectWithValue(null)
   }
})

export const pointsSlice = createSlice({
   name: 'pointsSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getPointsThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getPointsThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.data = payload
      })
      builder.addCase(getPointsThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = pointsSlice.actions

export default pointsSlice.reducer
