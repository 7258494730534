import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getHistory, GetHistoryRequest } from '../api/history'
import { HistoryItem } from '../components/partners/components/ActivityHistory'

export type HistoryState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<HistoryItem>
   totalSize: number
}

const initialState: HistoryState = {
   isPristine: true,
   isFetching: false,
   items: [],
   totalSize: 0,
}

export const getHistoryThunk = createAsyncThunk(
   'getHistory',
   async (request: GetHistoryRequest, { rejectWithValue }) => {
      try {
         return await getHistory(request)
      } catch {
         return rejectWithValue(null)
      }
   },
)

export const historySlice = createSlice({
   name: 'historySlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getHistoryThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
      })
      builder.addCase(getHistoryThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload.items
         state.totalSize = payload.totalSize
      })
      builder.addCase(getHistoryThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
      })
   },
})

export const { clear } = historySlice.actions

export default historySlice.reducer
