import React, { ReactNode } from 'react'
import './spinner.css'

export type Props = {
   className?: string
   style?: any
   children?: ReactNode
}
export const TextSpinner = ({ className = '', style, children }: Props) => (
   <div
      className={`itm-line-spinner ${className}`}
      {...(style
         ? { style }
         : {
              width: '75%',
              height: '14px',
           })}
   >
      {children}
   </div>
)
