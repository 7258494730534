import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getContractList, requestChannelList } from '../api/contracts'
export type ContractListState = {
   [key: string]: {
      isFetching: boolean
      items: Array<any>
   }
}

const initialState: ContractListState = {}
export const getContractListThunk = createAsyncThunk(
   'contractList/getContractList',
   async (request: requestChannelList, { rejectWithValue }) => {
      const { uri } = request
      try {
         const response = await getContractList(request)
         return { uri, items: response.items }
      } catch (error) {
         return rejectWithValue(null)
      }
   },
)
export const ContractListSlice = createSlice({
   name: 'contractListSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
   },
   extraReducers(builder) {
      builder.addCase(getContractListThunk.pending, (state, action) => {
         const { uri } = action.meta.arg
         state[uri] = {
            isFetching: true,
            items: [],
         }
      })
      builder.addCase(getContractListThunk.fulfilled, (state, action) => {
         const { uri, items } = action.payload
         state[uri] = {
            isFetching: false,
            items: items,
         }
      })
      builder.addCase(getContractListThunk.rejected, (state, action) => {
         const { uri } = action.meta.arg
         state[uri] = {
            isFetching: false,
            items: [],
         }
      })
   },
})

export const { clear } = ContractListSlice.actions

export default ContractListSlice.reducer
