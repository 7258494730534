import React, { createContext, ReactNode, useContext } from 'react'
import { UseFormReturn, UseFormGetValues, UseFormSetError } from 'react-hook-form'
interface FormContextProps {
   control: UseFormReturn<any>['control']
   formState: UseFormReturn<any>['formState']
   trigger: UseFormReturn<any>['trigger']
   setValue: UseFormReturn<any>['setValue']
   getValues: UseFormGetValues<any>
   setError: UseFormSetError<any>
   clearErrors: UseFormReturn<any>['clearErrors']
}
const FormContext = createContext<FormContextProps | undefined>(undefined)
export const useFormContext = () => {
   const context = useContext(FormContext)
   if (!context) {
      throw new Error('useFormContext must be used within a FormProvider')
   }
   return context
}
interface FormProviderProps {
   children: ReactNode
   value: FormContextProps
}

export const FormProvider = ({ children, value }: FormProviderProps) => {
   return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}
