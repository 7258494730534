type Props = {
   page: number
   size: number
   total: number
   name?: string
}
const TableResume = ({ page, size, total, name = '' }: Props) => {
   const drawCountList = () => {
      if (total === 0) {
         return null
      }
      if (page === 0) {
         return `${page + 1} a ${size < total ? size : total} de ${total} ${name}`
      } else {
         let pages = page * size
         let restPage = pages + size
         return `${pages + 1} a ${restPage < total ? restPage : total} de ${total} ${name}`
      }
   }
   return <>{drawCountList()}</>
}

export default TableResume
