import React, { useState } from 'react'
import Title from '../../common/Title'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetChannelList } from '../../../hooks/useGetChannelList'
import useGetPrograms from '../../../hooks/useGetPrograms'
import Forms, { Inputs } from './forms/Forms'
import { createPartner } from '../../../api/partner'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

type Props = {
   defautlValues?: Inputs
   onSubmit?: (data: Inputs) => void
}
const AddParter = (props: Props) => {
   const intl = useIntl()
   const { accountCode } = useParams()
   const [loading, setLoading] = useState(false)
   const { data: channels, isLoading: isLoadingChannels } = useGetChannelList()
   const { data: plans, isLoading: isLoadingPlans } = useGetPrograms()
   const navigate = useNavigate()
   const { mutate: submitForm } = useMutation({
      mutationFn: createPartner,
   })
   const onSubmit = (data: Inputs) => {
      const { loyaltyPlans: plans, swimmingSkill, ...rest } = data

      let request = {
         ...rest,
         plans: plans.map((plan) => ({
            ...plan,
            levelName: plan.level,
         })),
         ...(swimmingSkill && { swimmingSkill: swimmingSkill === 'Si' }),
      }

      submitForm(request, {
         onSuccess: () => {
            toast.success(intl.formatMessage({ id: 'partner.create.success' }))
            navigate(`/${accountCode}/partners`)
         },
         onError: (error: any) => {
            toast.error(
               error?.response?.data?.error
                  ? error.response.data.error
                  : intl.formatMessage({ id: 'partner.fail.error' }),
            )
            setLoading(false)
         },
      })
   }

   const onCancel = () => {
      navigate(`/${accountCode}/partners`)
   }

   return (
      <>
         <Title value={intl.formatMessage({ id: 'partner.create.tittle' })} />
         {isLoadingChannels || isLoadingPlans ? (
            <div className="flex justify-center items-center h-96">
               <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
         ) : (
            <Forms onSubmit={onSubmit} onCancel={onCancel} channels={channels} plans={plans} isloading={loading} />
         )}
      </>
   )
}

export default AddParter
