import { faBars, faSearch, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Logo } from './SideMenu'
import { useMemo, useRef, useState } from 'react'
import { OverlayTrigger } from 'ui'
import { loadAccount } from '../features/baseSlice'
import { logout } from '../features/authSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useLocation, useNavigate } from 'react-router-dom'

type Props = {
   onToggleMenu: () => void
}

const Header = ({ onToggleMenu }: Props) => {
   const dispatch = useAppDispatch()
   const auth = useAppSelector((state) => state.authToken)
   const profile = useAppSelector((state) => state.profile)
   const base = useAppSelector((state) => state.base)
   const [showProfileMenu, setShowProfileMenu] = useState(false)
   const [query, setQuery] = useState('')
   const inputSearch = useRef<HTMLInputElement>(null)
   const navigate = useNavigate()
   const { pathname } = useLocation()
   const pathSplited = pathname.split('/')
   const toggleProfileMenu = () => {
      setShowProfileMenu(!showProfileMenu)
      if (!showProfileMenu) {
         setTimeout(() => {
            inputSearch.current?.focus()
         }, 0)
      }
   }

   const filteredAccounts = useMemo(() => {
      const regex = new RegExp(query.trim(), 'i')
      const filteredItems = !query ? profile?.data?.accounts : profile?.data?.accounts.filter((i) => regex.test(i.name))
      return filteredItems
   }, [profile?.data?.accounts, query])

   function onChange(event: React.ChangeEvent<HTMLInputElement>) {
      setQuery(event.target.value)
   }

   const logOut = () => {
      dispatch(logout())
   }
   const handleOnClick = (account: any) => {
      setShowProfileMenu(false)
      setQuery('')
      dispatch(loadAccount(account))
      if (pathSplited.length > 2) {
         navigate(`/${account.code}/${pathSplited[2]}`, { replace: true })
      } else {
         navigate(`/${account.code}/dashboard`)
      }
      window.location.reload()
   }
   return (
      <header
         className={classNames(
            'fixed inset-x-0 top-0 z-50 flex h-16 items-center justify-between gap-12 px-4 transition sm:px-6 lg:z-30 lg:px-8 bg-amber-500 text-white',
            {
               'lg:left-[17rem]': auth && auth?.isAuthenticated,
            },
         )}
      >
         {auth && auth.isAuthenticated ? (
            <>
               <div className="flex gap-x-4 font-bold">
                  <button className="lg:hidden w-6 h-6" onClick={onToggleMenu}>
                     <FontAwesomeIcon icon={faBars} />
                  </button>
                  {base.account?.name || ''}
               </div>
               {profile.data && (
                  <div className="relative h-full">
                     <OverlayTrigger isOpen={showProfileMenu} onToggle={toggleProfileMenu}>
                        <OverlayTrigger.Trigger
                           componentTag="button"
                           className="flex items-center gap-x-2 focus:outline-none h-full"
                        >
                           <div className="bg-green-600 h-8 w-8 flex items-center justify-center text-white rounded-full">
                              {profile.data?.name.match(/\b\w/g)?.join('').toUpperCase()}
                           </div>
                           <div className="text-left leading-tight font-semibold">
                              {profile.data?.name}
                              {base.account && <div className="text-xs opacity-70">{base.account.name}</div>}
                           </div>
                        </OverlayTrigger.Trigger>
                        <OverlayTrigger.Overlay className="origin-top-right absolute right-0 -mt-1 w-64 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                           {profile?.data?.accounts.length > 1 && (
                              <>
                                 <div className="relative">
                                    {!query && (
                                       <FontAwesomeIcon
                                          icon={faSearch}
                                          className="absolute top-4 left-4 ml-0.5 text-gray-200"
                                       />
                                    )}
                                    <input
                                       ref={inputSearch}
                                       type="text"
                                       className="w-full px-3.5 py-3 outline-none text-sm text-gray-700"
                                       autoComplete="off"
                                       autoFocus
                                       value={query}
                                       onChange={onChange}
                                    />
                                 </div>
                                 <hr />
                                 <div className="max-w-[400px] overflow-y-auto">
                                    {query && (!filteredAccounts || filteredAccounts.length === 0) && (
                                       <div className="block p-4 text-sm text-gray-400 w-full text-left">
                                          No se encontraron resultados con el criterio de búsqueda
                                       </div>
                                    )}
                                    {filteredAccounts?.map((acc) => (
                                       <button
                                          key={acc.code}
                                          type="button"
                                          className="block p-4 text-sm text-gray-700 hover:bg-gray-100 w-full text-left text-ellipsis"
                                          onClick={() => handleOnClick(acc)}
                                       >
                                          {acc.name}
                                       </button>
                                    ))}
                                 </div>
                                 <hr />
                              </>
                           )}
                           <button
                              type="button"
                              className="block p-4 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                              onClick={logOut}
                           >
                              <FontAwesomeIcon icon={faSignOut} className="mr-2" />
                              Logout
                           </button>
                        </OverlayTrigger.Overlay>
                     </OverlayTrigger>
                  </div>
               )}
            </>
         ) : (
            <div>
               <Logo />
            </div>
         )}
      </header>
   )
}

export default Header
