import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getServiceTypeList } from '../../api/operation'

function useGetServiceTypes(request?: { loyaltyPlanId?: string; operationType?: string }) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_SERVICE_TYPE_LIST, request],
      queryFn: () => getServiceTypeList(request),
   })
}

export { useGetServiceTypes }
export default useGetServiceTypes
