import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import useGetPartner from '../../../hooks/useGetPartner'
import Title from '../../common/Title'
import Forms from './forms/Forms'
import useGetChannelList from '../../../hooks/useGetChannelList'
import useGetPrograms from '../../../hooks/useGetPrograms'
import { useMutation } from '@tanstack/react-query'
import { editPartner } from '../../../api/partner'
import { toast } from 'react-toastify'
import { useState } from 'react'

function EditPartner() {
   const intl = useIntl()
   const { id, accountCode } = useParams()
   const [loading, setLoading] = useState(false)
   const { data: partner, isLoading } = useGetPartner(id)
   const { data: channels, isLoading: isLoadingChannels } = useGetChannelList()
   const { data: plans, isLoading: isLoadingPlans } = useGetPrograms()
   const { mutate: submitForm } = useMutation({
      mutationFn: editPartner,
   })
   const navigate = useNavigate()
   const onSubmit = (data: any) => {
      const { loyaltyPlans, swimmingSkill, ...rest } = data
      setLoading(true)
      submitForm(
         {
            ...rest,
            plans: loyaltyPlans.map((plan: any) => ({
               id: plan.id,
               loyaltyPlanId: plan.loyaltyPlanId,
               registrationChannel: plan.registrationChannel,
               levelName: plan.level,
            })),
            ...(swimmingSkill && { swimmingSkill: swimmingSkill === 'Si' }),
            urlRefer: '',
         },
         {
            onSuccess: () => {
               toast.success(intl.formatMessage({ id: 'partner.edit.success' }))
               navigate(`/${accountCode}/partners`)
            },
            onError: (error: any) => {
               toast.error(
                  error?.response?.data?.error
                     ? error.response.data.error
                     : intl.formatMessage({ id: 'partner.edit.fail' }),
               )
               setLoading(false)
            },
         },
      )
   }
   const onCancel = () => {
      navigate(`/${accountCode}/partners/${id}`)
   }
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'partner.edit' })}
         ></Title>
         {isLoading || isLoadingChannels || isLoadingPlans ? (
            <div className="flex justify-center items-center h-96">
               <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
         ) : (
            <Forms
               isloading={loading}
               defautlValues={(partner as any) || undefined}
               onSubmit={onSubmit}
               onCancel={onCancel}
               channels={channels}
               plans={plans}
            />
         )}
      </>
   )
}

export default EditPartner
